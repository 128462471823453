import { Rule } from './rule';
import { every } from 'lodash-es';
import { FirestoreField } from '../firebase/firestore-field';
import { ModelProvider } from './general/model.provider';
import { Task } from './task.model';
import { Model } from './general/model';
import { FirestoreArrayField } from '../firebase/firestore-array-field';

export class Conditions {
  public static readonly AND = 'AND';
  public static readonly OR = 'OR';
}

export class Dependency extends FirestoreField {

  condition: string;
  ruleSet: FirestoreArrayField<Rule>;

  public static instantiate(data: any, model: Task, options: any, modelProvider: ModelProvider): Dependency {
    return new Dependency(data, model, options, modelProvider);
  }

  rules() {
    return {
      condition: {
        [Model.RULE_DEFAULT]: Conditions.AND
      },
      ruleSet: {
        [Model.RULE_ALIAS]: 'rules',
        [Model.RULE_CLASS]: FirestoreArrayField,
        [Model.RULE_MAP_TO_CLASS]: Rule,
        [Model.RULE_DEFAULT]: () => new FirestoreArrayField<Rule>([], this.documentInstance, {}, this.modelProvider)
      }
    };
  }
  isValid() {
    return this.condition
    && this.ruleSet.items.length > 0 ? every(this.ruleSet.items, (rule: Rule) => rule.isValid()) : true;
  }
}
