import { Component, OnInit, Input } from "@angular/core";
import { LoadingController, ModalController, IonButton, IonSpinner } from "@ionic/angular/standalone";
import { collection, query, where, getDocs, refEqual } from "@angular/fire/firestore";
import { httpsCallable } from "@angular/fire/functions";
import { User } from "src/app/core/models/user.model";
import { ModelProvider } from "src/app/core/models/general/model.provider";
import { OrganizationService } from "../organization.service";
import { Organization } from "src/app/core/models/organization.model";
import { WorkspaceService } from "src/app/core/workspace/workspace.service";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-organization-invite-modal",
    templateUrl: "./accept-invitation-modal.component.html",
    styleUrls: ["./accept-invitation-modal.component.scss"],
    imports: [IonButton, NgIf, TranslateModule, IonSpinner]
})
export class AcceptInvitationModalComponent implements OnInit {
  @Input()
  organization: {
    title: string;
    imageURL?: string;
    path: string;
    id: string;
  };
  @Input()
  inviter: string;
  @Input()
  invitee: string;
  @Input()
  level: string;
  @Input()
  userExists: string;

  email: string;
  processing = false;
  invitedBy: string;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private modelProvider: ModelProvider,
    private organizationService: OrganizationService,
    private workspaceService: WorkspaceService,
    private loadingCtrl: LoadingController,
     public translateService: TranslateService,
  ) {}

  async ngOnInit() {
    this.invitedBy = (await this.getUserByEmail(this.inviter)) || this.inviter;
  }

  onCancel() {
    this.modalCtrl.dismiss({ action: "cancel" });
  }

  async getUserByEmail(email: string): Promise<string | null> {
    const usersCollection = collection(this.modelProvider.fsDB, "users");
    const userQuery = query(usersCollection, where("email", "==", email));
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDoc = userQuerySnapshot.docs[0];
      const userData: User = userDoc.data() as User;
      return userData.displayName || userData.email;
    } else {
      return null;
    }
  }

  async updateActiveOrganization(organization: Organization) {
    if (!organization) return;
    const loading = await this.loadingCtrl.create({
      cssClass: "change-organization-loading",
      message: this.translateService.instant('playing/organization/change'),
    });
    await loading.present();
    try {
      const activeOrganization = organization;
      const workspaces = this.workspaceService.workspaces;
      let filteredWorkspaces = workspaces.filter((workspace) => {
        if (workspace.ownerOrganizationRef) {
          return refEqual(workspace.ownerOrganizationRef,activeOrganization.ref);
        } else {
          return activeOrganization.isOrphan();
        }
      });

      filteredWorkspaces = await this.modelProvider.asyncFilter(
        filteredWorkspaces,
        async (workspace) => (await workspace.getMyMember()).level !== "player"
      );

      filteredWorkspaces.sort((a, b) => {
        if (a.isPersonal && !b.isPersonal) {
          return -1;
        } else if (!a.isPersonal && b.isPersonal) {
          return 1;
        }

        if (a.myMember.joinedAt && !b.myMember.joinedAt) {
          return -1;
        } else if (!a.myMember.joinedAt && b.myMember.joinedAt) {
          return 1;
        } else if (a.myMember.joinedAt && b.myMember.joinedAt) {
          return a.myMember.joinedAt.toMillis() > b.myMember.joinedAt.toMillis()
            ? 1
            : -1;
        }

        return 0;
      });

      if (filteredWorkspaces.length > 0) {
        this.workspaceService.setActiveWorkspace(filteredWorkspaces[0]);
      }
      await this.organizationService.setOrganization(organization);
      this.router.navigateByUrl("/design");
    } catch (error) {
      throw error;
    } finally {
      await loading.dismiss();
    }
  }

  async onAccept() {
    try {
      this.processing = true;
      const fsFunction = httpsCallable(this.modelProvider.functions, "organizationAcceptInvitation");
      const response = await fsFunction({
        organizationId: this.organization.id,
        inviter: this.inviter,
        invitee: this.invitee,
        level: this.level,
        userExists: this.userExists,
      });
      const data: any = response.data;
      if (data.success) {
        this.organizationService.organizations$.subscribe(
          async (organizations) => {
            await this.updateActiveOrganization(
              organizations.find(
                (organization) => organization.id === this.organization.id
              )
            );
          }
        );
        this.modalCtrl.dismiss({ action: "success" });
      }
    } catch (error) {
      this.modalCtrl.dismiss({ action: "error" });
    } finally {
      this.processing = false;
    }
  }
}
