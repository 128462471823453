import { Component, OnInit, Input } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Plan } from 'src/app/core/models/plan';
import { Router } from '@angular/router';
import { ModalController, IonButton } from '@ionic/angular/standalone';
import { Organization } from 'src/app/core/models/organization.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-upgrade-required-modal',
    templateUrl: './upgrade-required-modal.component.html',
    styleUrls: ['./upgrade-required-modal.component.scss'],
    imports: [IonButton, NgIf, TranslateModule]
})
export class UpgradeRequiredModalComponent implements OnInit {
  @Input()
  public feature: string;

  @Input()
  public title: string;

  @Input()
  public organization: Organization;

  public plan: Plan;
  public current: number;
  public limit: number;

  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    if (!this.organization) {
      this.organization = await firstValueFrom(this.organizationService.activeOrganization$);
    }
    
    this.plan = await firstValueFrom(this.organization.plan$);

    switch (this.feature) {
      case 'workspace':
        const workspaces = await firstValueFrom(this.organization.workspaces$);

        this.current = workspaces.length;
        this.limit = this.plan.features.workspaceLimit;
        break;

      default:
        break;
    }
  }

  async upgrade() {
    await this.router.navigateByUrl('organization/create');
    await this.modalController.dismiss();
  }

  async cancel() {
    await this.modalController.dismiss();
  }

}
