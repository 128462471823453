<div class="modal-content ion-padding text-center">
  <ion-icon class="modal-close-icon" (click)="onCancel()" name="close" slot="icon-only"></ion-icon>
  <h2 class="modal-title mt-2">
    {{'browser-recommendation/title' | translate}}
  </h2>
  <div class="text-center">
    <img src="assets/images/browser-recommendation/chrome-logo.avif" />
  </div>
  <p>
    {{'browser-recommendation/lead' | translate}}
  </p>
  <ion-footer mode="ios">
    <div class="text-right2">
      <ion-button href="https://www.google.com/chrome" target="_blank" color="primary" shape="round">
        {{'browser-recommendation/download-button/label' | translate}}
      </ion-button>
    </div>
  </ion-footer>
</div>