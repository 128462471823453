import { Component } from '@angular/core';
import { ModalController, IonButton, IonFooter, IonIcon } from '@ionic/angular/standalone';
import { BrowserRecommendationService } from '../browser-recommendation.service';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

@Component({
    selector: 'app-browser-recommendation-modal',
    templateUrl: './browser-recommendation-modal.component.html',
    styleUrls: ['./browser-recommendation-modal.component.scss'],
    imports: [IonIcon, IonFooter, IonButton, TranslateModule]
})
export class BrowserRecommendationModalComponent {

  constructor(
    public modalController: ModalController,
    public browserRecommendationService: BrowserRecommendationService,
  ) { 
    addIcons({ close });
  }

  async onCancel() {
    await this.browserRecommendationService.modal.dismiss();
  }

}
