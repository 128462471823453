import { Injectable, Injector } from '@angular/core';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithRedirect, getRedirectResult, User as AuthUser, signOut, signInWithPopup, signInWithCustomToken, signInWithEmailLink, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithCredential, AuthProvider, AuthCredential, updateProfile, reload, getIdTokenResult, deleteUser } from '@angular/fire/auth';
import { Timestamp, setDoc, doc, DocumentReference, getDoc } from '@angular/fire/firestore';
import { httpsCallable } from '@angular/fire/functions';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ReplaySubject, Subscription, Observable, firstValueFrom, shareReplay } from 'rxjs';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { NavController, Platform, ToastController } from '@ionic/angular/standalone';
import { User } from '../core/models/user.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { environment } from 'src/environments/environment';
import { concatMap, filter } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { SignInWithApple, AppleSignInResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CloudMessagingService } from '../core/cloud-messaging/cloud-messaging.service';
import { OrganizationService } from '../organization/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { authState } from "@angular/fire/auth";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: AuthUser | null = null;

  constructor(
    public toastCtrl: ToastController,
    public translate: TranslateService,
    public appVersion: AppVersion,
    private device: Device,
    private platform: Platform,
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private firebaseMessaging: FirebaseMessaging,
    public analytics: AnalyticsProvider,
    private modelProvider: ModelProvider,
    private storage: Storage,
    private router: Router,
    private signInWithAppleService: SignInWithApple,
    private iab: InAppBrowser,
    private navCtrl: NavController,
    private cloudMessaging: CloudMessagingService,
    private injector: Injector,
    private translateService: TranslateService,
  ) {
    authState(this.modelProvider.fsAuth).subscribe((user)=>{
      this.user = user;
    });
  }

  private _user$: ReplaySubject<User>;
  public get user$(): ReplaySubject<User> {
    if (this._user$ !== undefined) { return this._user$; }
    this._user$ = new ReplaySubject<User>(1);

    let _userLoaded = undefined;

    authState(this.modelProvider.fsAuth).subscribe(async fsUser => {
      if (_userLoaded !== undefined && (fsUser && fsUser.uid) === (_userLoaded && _userLoaded.uid)) { return; }

      if (fsUser) {
        try {
          await getIdTokenResult(this.user, true);
        } catch (error) {
          console.error(error);
        }

        const organizationService = this.injector.get(OrganizationService);
        const organization = await organizationService.getDefaultActiveOrganization();
        await organizationService.setOrganization(organization);

        this.modelProvider.user.getMe$().subscribe(user => {
          _userLoaded = user;
          this._user$.next(user);
          this.isSuperAdmin().then()
        });
      } else {
        _userLoaded = null;
        this._user$.next(null);
      }
    });
    return this._user$;
  }

  async signUp(credentials) {
    if (credentials.name.length < 1) {
      return Promise.reject({ code: 'auth/name-too-short' });
    }
    try {
      const credential = await this.createUserWithEmailAndPassword(credentials);
      const user = await firstValueFrom(this.authState().pipe(filter(user => !!user)));
      await this.updateUserProfile(user, credentials);
      await this.saveUserData(credential.user, credentials);
      this.setAnalyticsUser(user, credentials);
      this.sendValidationEmail();
      return credential;
    } catch (error) {
      console.error(error);
      return this.handleExistingEmailError(error, credentials);
    }
  }
  async createUserWithEmailAndPassword(credentials) {
    return await createUserWithEmailAndPassword(this.modelProvider.fsAuth, credentials.email, credentials.password);
  }
  async updateUserProfile(user, credentials) {
    await updateProfile(user, { displayName: credentials.name });
    await reload(user);
  }
  async saveUserData(user, credentials) {
    const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
    const userRef: DocumentReference = doc(this.modelProvider.fsDB, 'users', user.uid);

    await setDoc(userRef, {
      email: credentials.email,
      displayName: credentials.name,
      company: credentials.company,
      newsletter: credentials.newsletter,
      emailNotifications: credentials.emailNotifications,
      language,
      roles: {
        [environment.app]: {
          active: true,
          lastLoginAt: new Date(),
        },
      },
    }, { merge: true });
  }
  setAnalyticsUser(user, credentials) {
    this.analytics.setUser(user);
    this.analytics.setUserProperties({
      newsletter: credentials.newsletter,
      company: credentials.company,
      'display-name': credentials.name,
    });
  }

  async handleExistingEmailError(error, credentials) {
    if (error.code === 'auth/email-already-in-use') {
      const toast = await this.toastCtrl.create({
        message: await firstValueFrom(this.translate.get(error.code || error)),
        duration: 3000,
        position: 'top',
        cssClass:'custom-toast'
      });
      await toast.present();
      console.log("on line no 154");
      
    }

    return Promise.reject(error);

  }

  async signOut(): Promise<void> {
    if (this.platform.is('cordova')) {
      const user = await firstValueFrom(this.user$);
      delete user.device[this.device.uuid];
      await user.save();
      try {
        await this.googlePlus.logout();
      } catch (error) {
        console.error(error);
      }

      try {
        await this.facebook.logout();
      } catch (error) {
        console.error(error);
      }
    }

    signOut(this.modelProvider.fsAuth).then(async (data) => {
      await firstValueFrom(authState(this.modelProvider.fsAuth).pipe(filter(user => !user)));
      try {
        localStorage.clear();
      } catch (error) {
        console.error(error);
      }

      try {
        sessionStorage.clear();
      } catch (error) {
        console.error(error);
      }

      try {
        this.analytics.logout();
      } catch (error) {
        console.error(error);
      }

      const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
      await firstValueFrom(this.translateService.use(language));
      this.translateService.setDefaultLang(language || 'en');

      try {
        await this.storage.clear();
        await this.storage.remove('redirectTo');
      } catch (error) {
        console.log(error);
      }
      return data;
    });
  }

  delete(): Promise<void> {
    return deleteUser(this.user);
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  async getHomeURL() {
    const storedRedirectTo = await this.storage.get('redirectTo');

    if (storedRedirectTo) {
      return storedRedirectTo;
    }

    return environment.homeURL;
  }

  async removeHomeURL() {
    return await this.storage.remove('redirectTo');
  }

  async setHomeURL(url: string) {
    if (url !== environment.homeURL) {
      return this.storage.set('redirectTo', url);
    }
  }

  async navigateToHome(redirectUrl?: string) {
    this.analytics.event('navigateToHome - 0');
    this.analytics.event('navigateToHome - 1', null, { homeURL: await this.getHomeURL() });

    if(redirectUrl){
      await this.navCtrl.navigateRoot(redirectUrl);
    }else{
      await this.navCtrl.navigateRoot(await this.getHomeURL());
    }
    
    this.analytics.event('navigateToHome - 2');
    await this.storage.remove('redirectTo')
    this.analytics.event('navigateToHome - 3');
  }

  signInWithEmail(credentials) {
    return signInWithEmailAndPassword(this.modelProvider.fsAuth, credentials.email, credentials.password);
  }

  async changeOrganization(organizationRef: DocumentReference) {
    const callable = httpsCallable(this.modelProvider.functions, 'authOrganizationChange');
    const response = await callable({
      organizationPath: organizationRef?.path || null,
    });

    const data = response.data as { success: boolean; token?: any; error?: any };

    if (data.success === true && data.token) {
      await this.signInWithCustomToken(data.token);
      await getIdTokenResult(this.user, true);
      return true;
    } else {
      throw data.error;
    }
  }
  
  async signInWithEmailAndPassword(email: string, password: string, organizationPath?: string) {
    const response: any = await this.modelProvider.callFunction("authEmailAndPassword",{
      email: email,
      password: password,
      organizationPath: organizationPath || null,
      provider: 'password'
    });
    const data = response as { success: boolean; token?: any; error?: any };
    if (data.success === true && data.token) {
      return await this.signInWithCustomToken(data.token);
    } else {
      throw data.error;
    }
  }

  async singInWithEmailLink(email, href) {
    return signInWithEmailLink(this.modelProvider.fsAuth, email, href);
  }

  async sendValidationEmail() {
    httpsCallable(this.modelProvider.functions,'sendVerificationEmail')({
      environment: environment,
      redirectUrl: await this.getHomeURL()
    });;
  }

  async signInWithFacebook() {
    try {
      if (this.platform.is('cordova')) {
        const connectedResult = await this.facebook.getLoginStatus();
        let accessToken = null;
        if (connectedResult.status === 'connected') {
          accessToken = connectedResult.authResponse.accessToken;
        } else {
          const loginResult = await this.facebook.login(['email']);
          accessToken = loginResult &&
            loginResult.authResponse &&
            loginResult.authResponse.accessToken;
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this

        const callable = httpsCallable(this.modelProvider.functions, 'authFacebook');
        const response = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'facebook'
        });

        const data = response.data as { success: boolean; token?: string; error?: any };

        if (data.success && data.token) {
          await this.signInWithCustomToken(data.token);
        }
        return await firstValueFrom(this.authState().pipe(filter(user => !!user)));
      } else {
        const provider = await new FacebookAuthProvider();
        provider.addScope('email');
        await signInWithPopup(this.modelProvider.fsAuth, provider).catch(async error => {
          if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
            const callable = httpsCallable(this.modelProvider.functions, 'authFacebook');
            const response = await callable({
              accessToken: error.credential.accessToken,
              environment: environment,
              provider: 'facebook'
            });
            const data  = response.data as { success: boolean; token?: string; error?: any };

            if (data.success && data.token) {
              await this.signInWithCustomToken(data.token);
              return await firstValueFrom(this.authState().pipe(filter(user => !!user)));
            }

            Promise.reject(error);
          } else {
            Promise.reject(error);
          }
        });
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithGoogle() {
    try {
      if (this.platform.is('cordova')) {
        const connectedResult = await this.googlePlus.trySilentLogin({}).catch(error => {
          return error;
        });
        let accessToken = null;

        if (connectedResult.accessToken) {
          accessToken = connectedResult.accessToken;
        } else {
          const loginResult = await this.googlePlus.login({
            webClientId: environment.auth.google.webClientID
          }).catch(error => {
            console.error('google login error: ');
            console.error(error);
            throw error;
          });
          accessToken = loginResult && loginResult.accessToken;
        }

        if (!accessToken) { throw new Error('Access rejected'); return; }

        // Check if user is already registered with other provider(s)
        // If registered sign in with custom token and link email provider to this

        const callable = httpsCallable(this.modelProvider.functions, 'authGoogle');
        const response = await callable({
          accessToken: accessToken,
          environment: environment,
          provider: 'google'
        });
        const data = response.data as { success: boolean; token?: string; error?: any };

        if (data.success && data.token) {
          await this.signInWithCustomToken(data.token);
        }

        const user = await firstValueFrom(this.authState().pipe(filter(user => !!user)));

        return user;
      } else {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        await signInWithPopup(this.modelProvider.fsAuth, provider).catch(async error => {
          if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
            const callable = httpsCallable(this.modelProvider.functions, 'authGoogle');
            const response = await callable({
              accessToken: error.credential.accessToken,
              environment: environment,
              provider: 'google'
            });
            const data = response.data as { success: boolean; token?: string; error?: any };
            if (data.success === true && data.token) {
              await this.signInWithCustomToken(data.token);
              return await firstValueFrom(this.authState().pipe(filter(user => !!user)));
            }

            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        });
      }

    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async signInWithApple() {
    try {
      if (this.platform.is('cordova') && this.platform.is('ios')) {
        await this.signInWithAppleService.signin({
          requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
          ]
        }).then(async (res: AppleSignInResponse) => {
          if (res.identityToken) {
            const callable = httpsCallable(this.modelProvider.functions, 'authApple');
            const response = await callable({
              res: res,
              accessToken: res.identityToken,
              environment: environment,
              provider: 'apple'
            });
            const data = response.data as { success: boolean; token?: string; error?: any };

            if (data.success && data.token) {
              await this.signInWithCustomToken(data.token);
            }

            const user = await firstValueFrom(this.authState().pipe(filter(user => !!user)));
            return user;
          }
        })
      } else {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        await signInWithPopup(this.modelProvider.fsAuth, provider).catch(async error => {
          console.error('apple error: ', error, ['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code));
            if (['auth/account-exists-with-different-credential'].find(errorCode => errorCode === error.code)) {
              const callable = httpsCallable(this.modelProvider.functions, 'authApple');
              const response = await callable({
                accessToken: error.credential.accessToken,
                environment: environment,
                provider: 'apple'
              });
              const data = response.data as { success: boolean; token?: string; error?: any };

              if (data.success && data.token) {
                await this.signInWithCustomToken(data.token);
                return await firstValueFrom(this.authState().pipe(filter(user => !!user)));
              }

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async signInWithMoodle(domain: string) {
    const self = this;

    return new Promise(async (resolve, reject) => {
      let browser;
      const subscriptions: Subscription[] = [];

      const url = domain + '/local/oauth/login.php?client_id=gamoteca&response_type=code';

      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/authMoodleRedirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                subscriptions.forEach(sub => sub.unsubscribe);
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            } finally {
              subscriptions.forEach(sub => sub.unsubscribe);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      if (this.platform.is('cordova')) {
        browser = this.iab.create(url, '_blank', 'height=315,width=400');
        subscriptions.push(
          browser.on('loadstop').subscribe((event) => { receiveMessage(event) })
        );
        subscriptions.push(
          browser.on('message').subscribe((event) => { receiveMessage(event) })
        );
      } else {
        browser = window.open(url, '_blank', 'height=315,width=400');
        browser.removeEventListener('loadstop', receiveMessage);
        browser.addEventListener('loadstop', receiveMessage);
      }

      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }

  public async signInWithKaya() {
    const self = this;

    return new Promise(async (resolve, reject) => {
      const receiveMessage = async (event) => {
        if (self.platform.is('cordova')) {
          if (event.url.indexOf('.cloudfunctions.net/redirect') !== -1) {
            try {
              (browser as any).executeScript({ code: 'error' }, async (values) => {
                browser.close();
                reject('auth/custom/access-denied');
              });
            } catch (error) {
              console.error(error);
            }

            try {
              (browser as any).executeScript({ code: 'token' }, async (values) => {
                if (values[0]) {
                  await self.signInWithCustomToken(values[0]);
                  resolve(true);
                }

                browser.close();
              });
            } catch (error) {
              console.error(error);
              reject(error);
            }

          }
        } else {
          if (event.data.method === 'loginWithCustomToken') {
            if (event.data.error) {
              reject('Invalid auth');
            } else {
              if (event.data.token) {
                await self.signInWithCustomToken(event.data.token);
                resolve(true);
              }
            }
          }
        }
      };

      const url = 'https://kayaconnect.org/local/oauth/login.php?client_id=gamoteca&response_type=code';
      const browser = window.open(url, '_blank', 'height=315,width=400');
      browser.removeEventListener('loadstop', receiveMessage);
      browser.addEventListener('loadstop', receiveMessage);
      window.removeEventListener('message', receiveMessage);
      window.addEventListener('message', receiveMessage, false);
    });
  }


  signInWithCustomToken(token) {
    return signInWithCustomToken(this.modelProvider.fsAuth, token);
  }

  private authWithCredential(credential: AuthCredential) {
    return signInWithCredential(this.modelProvider.fsAuth, credential).then((success) => {
      return success;
    }).catch((error) => {
      return error;
    });
  }

  private async oauthSignIn(provider: AuthProvider) {
    const self = this;
    if (!this.platform.is('cordova')) {
      await signInWithPopup(this.modelProvider.fsAuth, provider);
      return await firstValueFrom(this.authState());
    } else {
      if (provider instanceof FacebookAuthProvider) {
        return self.facebook.getLoginStatus().then(async (response) => {
          if (response.status === 'connected') {
            await self.authWithCredential(FacebookAuthProvider.credential(response.authResponse.accessToken));
            return await firstValueFrom(this.authState());
          } else {
            return self.facebook.login(['email']).then(async (_response) => {
              await self.authWithCredential(FacebookAuthProvider.credential(_response.authResponse.accessToken));
              return await firstValueFrom(this.authState());
            }).catch((_error) => {
              console.log('_error', _error);
            });
          }
        }).catch((error) => {
          console.log('error', error);
        });
      } else {
        return signInWithRedirect(this.modelProvider.fsAuth, provider).then(() => {
          return getRedirectResult(this.modelProvider.fsAuth).then(async (result) => {
            return await firstValueFrom(this.authState());
          }).catch(function (error) {
            alert(error.message);
          });
        });
      }
    }
  }

  public async isEmailVerified() {
    const user = await this.modelProvider.fsAuth.currentUser;
    if (user.emailVerified) {
      return true;
    }

    return false;
  }

  private _authState: Observable<AuthUser>;
  public authState(): Observable<AuthUser> {
    if (this._authState) {
      return this._authState;
    }

    this._authState = authState(this.modelProvider.fsAuth).pipe(
      concatMap(async (user: AuthUser | null) => {
        if (user && user.uid) {
          return await this.storeUserToDB(user).then(async ready => {
            await this.analytics.setUser(user);
            await this.analytics.setUserProperties({
              'display-name': user.displayName,
              email: user.email,
              ['is-' + environment.app]: true,
              'user_id': user.uid
            });
            return user;
          }).catch(error => {
            return Promise.reject(error);
          });
        } else {
          return user;
        }
      }),
      shareReplay(1)
    )

    return this._authState;
  }
  private async storeUserToDB(authUser: AuthUser): Promise<any> {
    const userDocRef = await doc(this.modelProvider.fsDB, 'users', authUser.uid);
    const fsDbUser = await getDoc(userDocRef);
    const fsDbUserData: any = fsDbUser.data() || {};

    const language = ['en', 'es', 'fr', 'ar'].find(language => language === this.translateService.getBrowserLang()) || 'en';
 
    const params: any = {
      ...fsDbUserData,
      displayName: fsDbUserData.displayName || authUser.displayName,
      email: fsDbUserData.email || authUser.email,
      photoURL: fsDbUserData.photoURL || authUser.photoURL,
      phoneNumber: fsDbUserData.phoneNumber || authUser.phoneNumber,
      language: fsDbUserData?.language || language,
      lastLoginAt: Timestamp.now(),
      roles: {
        [environment.app]: {
          active: true,
          lastLoginAt:  Timestamp.now(),
        }
      }
    };

    if (!fsDbUser.exists || !fsDbUserData.roles || !fsDbUserData.roles[environment.app] || !fsDbUserData.roles[environment.app].registeredAt) {
      params.roles[environment.app].registeredAt = Timestamp.now();
    }

    if (authUser.displayName && !fsDbUserData.displayName) {
      params.displayName = authUser.displayName;
    }

    if (this.platform.is('cordova')) {
      httpsCallable(this.modelProvider.functions,'authSetIP')({ deviceID: this.device.uuid });
      params.device = {
        [this.device.uuid]: {
          platform: this.device.platform,
          manufacturer: this.device.manufacturer,
          model: this.device.model,
          serial: this.device.serial,
          uuid: this.device.uuid,
          version: this.device.version,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: Timestamp.now(),
          pushNotificationToken: await this.firebaseMessaging.getToken(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: Timestamp.now(),
            }
          }
        }
      };
    } else {
      httpsCallable(this.modelProvider.functions,'authSetIP')({ deviceID: 'web' });
      params.device = {
        web: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent,
          appVersion: await this.getAppVersion(), // TODO,
          lastLoginAt: Timestamp.now(),
          roles: {
            [environment.app]: {
              active: true,
              lastLoginAt: Timestamp.now(),
            }
          }
        }
      };
    }

    const partnerProviderID = sessionStorage.getItem('partnerProviderID')
    const partnerEncryptedParams = sessionStorage.getItem('partnerEncryptedParams');
    if (partnerProviderID && partnerEncryptedParams) {
      httpsCallable(this.modelProvider.functions, 'addMoodleProvider')({
        providerID: partnerProviderID,
        encryptedParams: partnerEncryptedParams,
      }).then(async result => {
        await getIdTokenResult(this.user, true);
        sessionStorage.removeItem('partnerProviderID');
        sessionStorage.removeItem('partnerEncryptedParams');
      });
    }
    return setDoc(userDocRef, params, { merge: true });
  }

  public getDeviceID() {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      return 'web';
    }
  }

  public async getAppVersion(): Promise<string> {
    if (this.platform.is('cordova')) {
      await this.platform.ready();
      return await this.appVersion.getVersionNumber().catch(error => { throw error; });
    } else {
      return await this.readConfig().then(config => config.appVersion);
    }
  }

  private cache = null;
  private readConfig(): Promise<any> {
    const self = this;
    return new Promise<string>((resolve, reject) => {
      if (this.cache === null) {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('load', function () {
          try {
            const parser = new DOMParser();
            const document = parser.parseFromString(xhr.responseText, 'application/xml');
            const widget = document.getElementsByTagName('widget').item(0);

            self.cache = {
              appVersion: widget.getAttribute('version'),
              appName: widget.getElementsByTagName('name').item(0).textContent,
              packageName: widget.getAttribute('id'),
              versionCode: widget.getAttribute('browser-versionCode')
            };
            resolve(self.cache);
          } catch (e) {
            reject(e);
          }
        });

        xhr.addEventListener('error', function (e) {
          reject(e);
        });
        xhr.open('get', '../assets/config.xml', true);
        xhr.send();
      } else {
        setTimeout(function () {
          resolve(self.cache);
        }, 0);
      }
    });
  }

  async isSuperAdmin() {
    const fbUser = await firstValueFrom(this.authState());
    if (!fbUser) { return false };

    const tokenResult = await getIdTokenResult(this.user);

    return tokenResult.claims.superadmin ? true : false;
  }
}