import { FirestoreModel } from '../firebase/firestore-model';
import { Model } from './general/model';
import { LocalizedText } from './general/localized-text';
import { ScriptVersion } from './script-version.model';
import { Timestamp, collection, doc, updateDoc } from "@angular/fire/firestore";

export class Role extends FirestoreModel<Role> {
  public _title: LocalizedText;
  public get title(): string {
    return this._title.getText();
  }
  public set title(text: string) {
    this._title.setText(text);
  }
  public color?: string;
  public order?: number;
  public legacy?: {
    roleID?: string;
  };
  public type?: string;

  protected rules() {
    return {
      _title: { [Model.RULE_ALIAS]: 'title', [Model.RULE_CLASS]: LocalizedText },
      order: {},
      color: {},
      legacy: {},
      type: {},
    };
  }

  public async save() {
    await super.save();
    if (this.getReference()?.parent?.parent) {
      const parentDocRef = doc(this.modelProvider.fsDB, this.getReference().parent.parent.path);
      await updateDoc(parentDocRef, { updatedAt: Timestamp.now() });
    }
  }

  public findAllByVersion(scriptVersion: ScriptVersion) {
    const rolesCollection = collection(this.modelProvider.fsDB, scriptVersion.ref.path, 'roles');
    return this.findAllBy(null, rolesCollection);
  }

  public findByID(id: string, scriptId?: string, versionId?: string) {
    if (scriptId && versionId) {
      return this.findByRef('scripts/' + scriptId + '/versions/' + versionId + '/roles/' + id);
    } else {
      return super.findByID(id);
    }
  }

  protected instantiate(path, data, options?: any) {
    return new Role(path, data, options, this.modelProvider);
  }

  public async createDefaultRolesForVersion(scriptVersion: ScriptVersion) {
    const roles: Array<Role> = [];
    const player1 = this.createDefaultRole('Player 1', '#ff0000', scriptVersion, roles);
    const player2 = this.createDefaultRole('Player 2', '#0099dd', scriptVersion, roles);
    roles.push(player1);
    roles.push(player2);
    for (const role of roles) {
      await role.save();
    }
  }

  private createDefaultRole(title: string, color: string, scriptVersion: ScriptVersion, roles: Array<Role>) {
    return this.modelProvider.role.create(scriptVersion.getDocument().path + '/roles', {
      title: title,
      color: color,
      order: roles.length + 1,
      legacy: {},
      type: 'player'
    });
  }
}
