import { FirestoreModel } from '../firebase/firestore-model';
import { take, switchMap, shareReplay, map } from 'rxjs/operators';
import { Observable, ReplaySubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { Workspace, WorkspaceLevel } from './workspace.model';
import { DocumentReference, doc, Timestamp } from '@angular/fire/firestore';
import { OrganizationMember } from './organization-members.model';
import { Organization } from './organization.model';
import { Model } from './general/model';
import { httpsCallable } from '@angular/fire/functions';
import { ref, uploadBytesResumable } from "@angular/fire/storage";

export class User extends FirestoreModel<User> {
  public COLLECTION_NAME = 'users';
  public device: any;
  private _displayName: string;
  public get displayName(): string {
    if (this._displayName && this._displayName.length > 0) {
      return this._displayName;
    }

    return this.email && this.email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, "$1***@$2")
  }
  public set displayName(text: string) {
    this._displayName = text;
  }
  public email: string;
  public newsletter: boolean;
  public emailNotifications: boolean;
  public lastLoginAt: Timestamp;
  public organizations: Object = {};
  public phoneNumber: string;
  public language: string | 'en' | 'es';
  public location: string;
  public aboutMe: string;
  public role: string;
  public roles: {
    designer: {
      active: boolean;
      lastLoginAt: Timestamp,
      registeredAt: Timestamp,
    },
    player: {
      active: boolean;
      lastLoginAt: Timestamp,
      registeredAt: Timestamp,
    },
  }
  public deleted: {
    is: boolean,
    at: Timestamp,
    by: DocumentReference
  }
  public version: string;
  public kaya: {
    id: string;
  }
  public providers: {
    [providerID: string]: {
      active: boolean,
      organizationRef: DocumentReference,
      userID: string
    }
  }
  public workspaces: {
    [workspaceID: string]: {
      active: boolean,
      level: WorkspaceLevel,
      workspaceRef: DocumentReference,
      joinedAt: Timestamp
    }
  };

  private _photoURL: string;

  protected rules() {
    return {
      createdAt: {},
      device: {},
      _displayName: {
        [Model.RULE_ALIAS]: 'displayName',
      },
      email: {},
      lastLoginAt: {},
      organizations: {},
      phoneNumber: {},
      photoURL: {},
      language: {},
      location: {},
      aboutMe: {},
      role: {},
      roles: {},
      version: {},
      kaya: {},
      providers: {},
      workspaces: {},
      newsletter: {},
      emailNotifications: {},
      deleted: {}
    };
  }

  get photoURL(): string {
    return this._photoURL ?
      this._photoURL :
      'https://firebasestorage.googleapis.com/v0/b/gamoteca-161414.appspot.com/o/users%2Ffacilitator%2Favatar.png?alt=media&token=5265ea4d-525a-4212-873c-96aa13c9b649';
  }

  set photoURL(value: string) {
    this._photoURL = value;
  }

  isProfileDataMissing() {
    return !this.location || !this.aboutMe || !this.language;
  }

  protected instantiate(path, data, options?: any) {
    return new User(path, data, options, this.modelProvider);
  }

  public async getMe(): Promise<User> {
    const auth = this.modelProvider.fsAuth;
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return null;
    }
    return await firstValueFrom(this.findByID(currentUser.uid).pipe(take(1)));
  }

  public getMe$(): Observable<User> {
    const auth = this.modelProvider.fsAuth;
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return of(null);
    }else{
      return this.findByID(currentUser.uid);
    }
  }

  public get languageText() {
    if(!this.language) { return null }
    const languages = [
      {
        code: 'en',
        language: 'english'
      },
      {
        code: 'es',
        language: 'spanish'
      }
    ];
    return languages.find(languageItem => languageItem.code === this.language)?.language
  }

  public get facilitatorRef() {
    return doc(this.modelProvider.fsDB, 'users/facilitator');
  }

  public get meReference() {
    const auth = this.modelProvider.fsAuth;
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return null;
    }
    return doc(this.modelProvider.fsDB, "users", currentUser.uid);
  }

  public get isMe() {
    return this.ref.id === this.modelProvider.fsAuth.currentUser?.uid;
  }

  public get isFacilitator() {
    return this.ref.path === this.facilitatorRef.path;
  }

  /**
   * Workspace
   */
  private _workspaces$: Observable<Workspace[]>;
  public get workspaces$(): Observable<Workspace[]> {
    if (this._workspaces$ !== undefined) { return this._workspaces$; }
    this._workspaces$ = this.modelProvider.workspace.findAllByUser(this.getReference())
    return this._workspaces$;
  }

  /**
   * Organization Members
   */
  private _organizations$: ReplaySubject<Organization[]>;
  public get organizations$(): Observable<Organization[]> {
    if (this._organizations$ !== undefined) { return this._organizations$; }

    this._organizations$ = new ReplaySubject<Organization[]>(1);
    this.modelProvider.organizationMember
      .findAllByUser$(this)
      .pipe(
        switchMap(organizationMembers => {
          return combineLatest(
            organizationMembers.map(member =>
              this.modelProvider.organization
                .findByRef(member.organizationRef)
            )
          );
        })
      )
      .subscribe(this._organizations$);
    return this._organizations$;
  }
  /**
   * Organization Members
   */
  private _organizationsPartOf$: Observable<Organization[]>;
  public get organizationsPartOf$(): Observable<Organization[]> {
    if (this._organizationsPartOf$ !== undefined) { return this._organizationsPartOf$; }

    const organizationsByWorkspaceMember$ = this.workspaces$.pipe(
      switchMap(async workspaces => {
        await this.modelProvider.asyncFilter(workspaces, async (workspace: Workspace) => {
          return (await workspace.getMyMember()).level !== 'player'
        });

        return workspaces;
      }),
      switchMap(workspaces => {
        if (workspaces.filter(workspace => workspace.ownerOrganizationRef).length === 0) {
          return of([]);
        }

        return combineLatest(
          workspaces
            .filter(workspace => workspace.myMember.level !== 'player')
            .filter(workspace => workspace.ownerOrganizationRef)
            .map(workspace => this.modelProvider.organization.findByRef(workspace.ownerOrganizationRef))
        );
      })
    )

    const organizationsByMember$ = this.modelProvider.organizationMember
      .findAllByUser$(this)
      .pipe(
        switchMap(organizationMembers => {
          if (organizationMembers.length === 0) {
            return of([]);
          }
          return combineLatest(
            organizationMembers
            .filter(member => member.level !== 'player' && member.isInvitationAccepted)
            .map(member =>this.modelProvider.organization.findByRef(member.organizationRef)
            )
          );
        })
      )

    this._organizationsPartOf$ = combineLatest([organizationsByWorkspaceMember$, organizationsByMember$])
      .pipe(map(([organizationsByWorkspaceMember, organizationsByMember]) => organizationsByWorkspaceMember.concat(organizationsByMember)))
      .pipe(map(organizations => organizations
        .filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.ref.path).indexOf(obj.ref.path) === pos;
        })
      ))
      .pipe(shareReplay(1))

    return this._organizationsPartOf$;
  }

  /**
   * Organizations
   */
  private _organizationMembers$: Observable<OrganizationMember[]>;
  public get organizationMembers$(): Observable<OrganizationMember[]> {
    if (this._organizationMembers$) { return this._organizationMembers$; }
    this._organizationMembers$ = this.modelProvider.user.getMe$().pipe(switchMap(user => {
      return this.modelProvider.organizationMember.findAllByUser$(user);
    }));
    return this._organizationMembers$;
  }

  uploadFile(category: string, file: File, metadata?: any) {
    const reference = ref(this.modelProvider.fsStorage, this.getStorageReferencePath(category, file));
    const uploadTask = uploadBytesResumable(reference, file, metadata);
    return {
      ref: reference,
      uploadTask
    };
  }

  getStorageReferencePath(category: string, file: File) {
    return this.getDocument().path + '/' + category + '/' + file.name;
  }

  joinToWorkspace(workspace: Workspace, timestamp: Timestamp) {
    if (this.workspaces[workspace.id] && !this.isJoinedToTheWorkspace(workspace)) {
      this.workspaces[workspace.id].joinedAt = timestamp;
      this.save();
    }
  }

  isJoinedToTheWorkspace(workspace: Workspace) {
    return this.workspaces[workspace.id] && this.workspaces[workspace.id].joinedAt;
  }

  getProviderByOrganizationRef(organizationRef: DocumentReference) {
    if (!organizationRef) { return false; }

    return Object.values(this.providers).find(provider => provider.organizationRef.path === organizationRef.path);
  }

  async deleteAccount() {
    return await httpsCallable(this.modelProvider.functions,'deleteUserAccount')({});
  }
}