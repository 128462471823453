import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { firstValueFrom, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ColorService } from '../color/color.service';
import { ModelProvider } from '../models/general/model.provider';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  logoURL$ = new ReplaySubject<string>(1);

  color$ = new ReplaySubject<{
    primary: string,
    warning: string,
  }>(1);

  default = {
    logoURL: '/assets/layout/header/logo_horizontal_black.avif',
    color: {
      primary: '#20cfc6',
      warning: '#dd753b'
    }
  }

  subscriptions: Subscription[] = [];

  static STORAGE_ID = 'brand_ID';
  static DEFAULT_BRAND_ID = 'gamoteca';

  brand$ = new ReplaySubject<string>(1);

  constructor(
    private modelProvider: ModelProvider,
    private colorService: ColorService,
    private storage: Storage
  ) {

  }

  async init() {
    const storedBrandID = await this.storage.get(BrandingService.STORAGE_ID);
    this.setBrand(storedBrandID ? storedBrandID : BrandingService.DEFAULT_BRAND_ID);

    this.subscriptions.push(
      this.brand$.pipe(debounceTime(50)).subscribe(async brand => {
        if (brand === BrandingService.DEFAULT_BRAND_ID) {
          this.setDefault();
        } else {
          await this.setByOrganizationID(brand);
        }
        await this.storage.set(BrandingService.STORAGE_ID, brand);
      })
    );

    this.subscriptions.push(
      this.color$.subscribe(color => {
        this.colorService.setIonicColor('primary', color.primary)
        this.colorService.setIonicColor('warning', color.warning)
      })
    );

    await firstValueFrom(this.brand$);
  }

  destroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async setBrand(brandID: string) {
    this.brand$.next(brandID);
  }

  async isDefault() {
    const brandID = await firstValueFrom(this.brand$);
    if (brandID === BrandingService.DEFAULT_BRAND_ID) {
      return true;
    }
    const organization = await firstValueFrom(this.modelProvider.organization.findByID(brandID));
    if (!organization || organization?.title === 'Gamoteca') {
      return true;
    }
    return false;
  }

  private async setByOrganizationID(id: string) {
    const organization = await firstValueFrom(this.modelProvider.organization.findByID(id));
    if (organization?.color) {
      this.logoURL$.next(organization.imageUrl);
      this.color$.next(organization.color);
    } else {
      this.setDefault();
      if (organization?.imageUrl) {
        this.logoURL$.next(organization.imageUrl);
      }
    }
  }

  private setDefault() {
    this.logoURL$.next(this.default.logoURL);
    this.color$.next(this.default.color);
  }


}
