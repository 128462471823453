import { Injectable } from '@angular/core';
import { Task } from '../task.model';
import { Role } from '../role.model';
import { Play } from '../play.model';
import { Team } from '../team.model';
import { Player } from '../player.model';
import { User } from '../user.model';
import { Script } from '../script.model';
import { LocalizeService } from '../../localize/localize.service';
import { ScriptVersion } from '../script-version.model';
import { TranslateService } from '@ngx-translate/core';
import { Workspace } from '../workspace.model';
import { Config } from '../config.model';
import { FirebaseCache } from '../../firebase/firebase-cache';
import { AlertController, Platform } from '@ionic/angular/standalone';
import { Organization } from '../organization.model';
import { OrganizationMember } from '../organization-members.model';
import { Plan } from '../plan';
import { Storage } from '@ionic/storage';
import { WorkspaceMember } from '../workspace-member';
import { FeedPost } from '../feed-post';
import { Feed } from '../feed';
import { Firestore } from '@angular/fire/firestore';
import { Storage as FirebaseStorage } from "@angular/fire/storage";
import { Auth } from "@angular/fire/auth";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { Analytics } from "@angular/fire/analytics";
import { Messaging } from "@angular/fire/messaging";
import { RemoteConfig } from "@angular/fire/remote-config";

@Injectable({
    providedIn: 'root'
})
export class ModelProvider {
    get script(): Script {
        return new Script('', {}, {}, this);
    }

    get scriptVersion(): ScriptVersion {
        return new ScriptVersion('', {}, {}, this);
    }

    get task(): Task {
        return new Task('', {}, {}, this);
    }

    get role(): Role {
        return new Role('', {}, {}, this);
    }

    get play(): Play {
        return new Play('', {}, {}, this);
    }

    get team(): Team {
        return new Team('', {}, {}, this);
    }

    get player(): Player {
        return new Player('', {}, {}, this);
    }

    get user(): User {
        return new User('', {}, {}, this);
    }

    get workspace(): Workspace {
        return new Workspace('', {}, {}, this);
    }

    get workspaceMember(): WorkspaceMember {
        return new WorkspaceMember('', {}, {}, this);
    }

    get organization(): Organization {
        return new Organization('', {}, {}, this);
    }

    get organizationMember(): OrganizationMember {
        return new OrganizationMember('', {}, {}, this);
    }

    get config(): Config {
        return new Config('', {}, {}, this);
    }

    get plan(): Plan {
        return new Plan('', {}, {}, this);
    }

    get feed(): Feed {
        return new Feed('', {}, {}, this);
    }

    get feedItem(): FeedPost {
        return new FeedPost('', {}, {}, this);
    }

    public constructor(
        public fsDB: Firestore,
        public fsStorage: FirebaseStorage,
        public functions: Functions,
        public fsAuth: Auth,
        public fsAnalytics: Analytics,
        public fsMessaging: Messaging,
        public fsRemoteConfig : RemoteConfig,
        public localize: LocalizeService,
        public translate: TranslateService,
        public cache: FirebaseCache,
        public alertController: AlertController,
        public storage: Storage,
        public platform: Platform
    ) {}

    callFunction(name: string, data: any, options?: any) : Promise<any>{
        const callable = httpsCallable(this.functions, name, options);
        return callable(data).then((result) => result.data);
    }

    async alertLog(message: string) {
        const alert = await this.alertController.create({
            subHeader: new Date().toString(),
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    public async asyncFilter<T>(arr: Array<T>, predicate) {
        return Promise.all(arr.map(predicate)).then((results) => arr.filter((_v, index) => results[index]));
    }

    trackById(index, item) {
        return item.id;
    }
}
