<div class="modal-content ion-padding">
  <h2 class="modal-title">
    <ng-container *ngIf="title">
      {{title}}
    </ng-container>
    <ng-container *ngIf="!title">
      {{'upgrade-required/'+ feature + '-limit/title' | translate}}
    </ng-container>
  </h2>

  <ng-container *ngIf="organization && !organization.isActive && plan">
    <p>The subscription for your Gamoteca {{plan.title}} plan is inactive
      ({{'organization/plan/status/' + organization.plan.status | translate}}).</p>
    <p>
      If you would like to continue benefiting from Gamoteca {{plan.title}}’s features, please reactivate your
      subscription under Manage Team (Billing).
    </p>
    <ng-container *ngIf="['incomplete_expired', 'canceled'].includes(organization?.plan?.status)">
      <p>Note: You will need to create a new Team account and will need to move your team’s shared Workspaces to the new
        team account.
      </p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="organization && organization.isActive && plan">
    <ng-container *ngIf="feature === 'workspace' && plan">
      <p>
        You have reached the maximum number of workspace allowed under your current subscription. Please consider
        upgrading to
        <ng-container *ngIf="plan.id === 'free'">a Pro account</ng-container>
        <ng-container *ngIf="plan.id !== 'free'">an Enterprise account</ng-container>
        if you want access to higher number of workspaces.
      </p>
    </ng-container>

    <ng-container *ngIf="feature === 'script' && plan">
      <p>
        You have reached the maximum number of games in a Workspace allowed under your current subscription. Please consider
        upgrading to
        <ng-container *ngIf="plan.id === 'free'">a Pro account</ng-container>
        <ng-container *ngIf="plan.id !== 'free'">an Enterprise account</ng-container>
        if you want access to higher number of games.
      </p>
    </ng-container>

    <ng-container *ngIf="feature === 'designer' && plan">
      <p>
        You have reached the maximum number of members in a Workspace allowed under your current subscription. Please consider
        upgrading to
        <ng-container *ngIf="plan.id === 'free'">a Pro account</ng-container>
        <ng-container *ngIf="plan.id !== 'free'">an Enterprise account</ng-container>
        if you want access to higher number of members.
      </p>
    </ng-container>

    <ng-container *ngIf="feature === 'plan-status' && plan">
      <h2 class="modal-title">{{'upgrade-required/plan-status/title' | translate}}</h2>
      <p *ngIf="!['active', 'trialing'].includes(organization.plan.status)">
        {{'upgrade-required/organization/status' | translate: {status: 'organization/plan/status/' + organization.plan.status | translate } }}
      </p>
    </ng-container>
  </ng-container>


  <div class="mt-4 text-right">
    <ion-button (click)="cancel()" class="mr-3" color="secondary" shape="round">{{'general/cancel' | translate}}
    </ion-button>
    <ion-button (click)="upgrade()" shape="round" color="primary">
      <ng-container *ngIf="['incomplete_expired', 'canceled'].includes(organization.plan.status)">
        Create new team account
      </ng-container>
      <ng-container *ngIf="!['incomplete_expired', 'canceled'].includes(organization.plan.status)">
        {{'upgrade-required/upgrade-button/label' | translate}}
      </ng-container>
    </ion-button>
  </div>
</div>