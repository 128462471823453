import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { WelcomeAnalyticsService } from './welcome-analytics-service';
import { ActionSheetController, NavController, Platform, ToastController, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonSpinner, IonButton } from '@ionic/angular/standalone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { filter, first, map } from 'rxjs/operators';
import { ConfigAuth } from '../core/models/config.model';
import { ModelProvider } from '../core/models/general/model.provider';
import { firstValueFrom, Subscription } from 'rxjs';
import { BrandingService } from '../core/branding/branding.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { logoGoogle, logoApple, logoFacebook, mailSharp } from 'ionicons/icons';
import { where } from '@angular/fire/firestore';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome-page.component.html',
    styleUrls: ['./welcome-page.component.scss'],
    imports: [IonIcon, IonCol, IonRow, IonGrid, IonContent, IonSpinner, IonButton, NgIf, TranslateModule, FormsModule, RouterModule, TranslateModule],
    providers: [
        WelcomeAnalyticsService
    ]
})
export class WelcomePageComponent implements OnInit {

  public processing = false;
  public actual = null;
  partner: boolean | string;
  public partnerProvider: {
    id: string;
    logoURL: string;
    title: string;
    type: string;
    url: string;
  };
  private configAuth: ConfigAuth;

  public isAppleSignInAvailable = false;
  public language = this.translate.currentLang;

  private subscriptions: Subscription[] = [];
  public redirectUrl: string = null;

  constructor(
    private router: Router,
    private analytics: WelcomeAnalyticsService,
    public platform: Platform,
    public toastCtrl: ToastController,
    public translate: TranslateService,
    private auth: AuthService,
    private modelProvider: ModelProvider,
    private navCtrl: NavController,
    private ref: ChangeDetectorRef,
    public actionSheetController: ActionSheetController,
    public brandingService: BrandingService
  ) { 
    addIcons({ logoGoogle, logoApple, logoFacebook, mailSharp });
  }

  login() {
    this.analytics.createSignInClickedEvent();
    this.router.navigate(['auth', 'login'], {
      queryParams: {
        redirectUrl: this.redirectUrl
      }
    });
  }

  public async doLoginMoodle(provider: {
    id: string;
    logoURL: string;
    title: string;
    type: string;
    url: string;
  }) {
    const domain = provider.url;
    this.actual = 'moodle';
    this.processing = true;
    try {
      await this.auth.signInWithMoodle(domain);
      await firstValueFrom(this.auth.authState().pipe(filter(user => !!user)));
      const organization = await firstValueFrom(this.modelProvider.organization
        .findAllBy([where('integrations.providerID', '==', provider.id)])
        .pipe(map(organizations => organizations[0]), first()));
      await this.brandingService.setBrand(organization.id);
      await this.auth.navigateToHome();
    } catch (error) {
      const toast = await this.toastCtrl.create({
        message: await firstValueFrom(this.translate.get(error.code || error)),
        duration: 3000,
        position: 'top'
      });
      await toast.present();
    } finally {
      this.processing = false;
      this.actual = null;
    }
  }

  public async doLoginFB() {
    this.actual = 'facebook';
    this.processing = true;
    this.analytics.createSignInWithFacebookClickedEvent();
    try {
      await this.auth.signInWithFacebook();
      await firstValueFrom(this.auth.authState().pipe(filter(user => !!user)));
      this.auth.analytics.setUserProperties({ 'auth-facebook': true });
      await this.auth.navigateToHome(this.redirectUrl);
    } catch (error) {
      console.error(error);
      const toast = await this.toastCtrl.create({
        message: await firstValueFrom(this.translate.get(error.code || error)),
        duration: 3000,
        position: 'top'
      });
      await toast.present();
    } finally {
      this.processing = false;
      this.actual = null;
    }
  }

  public async doLoginGoogle() {
    this.actual = 'google';
    this.processing = true;
    this.analytics.createSignInWithGoogleClickedEvent();
    try {
      await this.auth.signInWithGoogle();
      await firstValueFrom(this.auth.authState().pipe(filter(user => !!user)));
      this.auth.analytics.setUserProperties({ 'auth-google': true });
      await this.auth.navigateToHome(this.redirectUrl);
    } catch (error) {
      console.error(error);
      const toast = await this.toastCtrl.create({
        message: await firstValueFrom(this.translate.get(error.code || error)),
        duration: 3000,
        position: 'top'
      });
      await toast.present();
    } finally {
      this.processing = false;
      this.actual = null;
    }
  }

  public async doLoginApple() {
    this.actual = 'apple';
    this.processing = true;
    this.analytics.createSignInWithAppleClickedEvent();
    try {
      await this.auth.signInWithApple();
      await firstValueFrom(this.auth.authState().pipe(filter(user => !!user)));
      this.auth.analytics.setUserProperties({ 'auth-apple': true });
      await this.auth.navigateToHome(this.redirectUrl);
    } catch (error) {
      console.error(error);
      const toast = await this.toastCtrl.create({
        message: await firstValueFrom(this.translate.get(error.code || error)),
        duration: 3000,
        position: 'top'
      });
      await toast.present();
    } finally {
      this.processing = false;
      this.actual = null;
    }
  }

  async initPartner() {
    this.ref.detach();
    const partner = this.router.routerState.snapshot.root.queryParamMap.get('partner');
    this.partner = partner || false;

    this.partnerProvider = this.configAuth.alternateProviders.find(provider => provider.id === this.partner);

    if (this.partnerProvider) {
      this.analytics.partnerOpened(this.partnerProvider.id, this.partnerProvider.title);
    } else {
      this.partner = false;
    }

    this.ref.detectChanges();
    this.ref.markForCheck();
    this.ref.reattach();
  }

  async ngOnInit() {
    const user = await firstValueFrom(this.auth.authState());
    if (user) {
      await this.auth.navigateToHome();
    }

    this.configAuth = await firstValueFrom(this.modelProvider.config.auth$);

    this.subscriptions.push(
      this.router.routerState.root.queryParams.subscribe(async params => {
        if (params && params.redirectUrl) {
          this.redirectUrl = params.redirectUrl;
        }

        await this.navCtrl.navigateRoot([], {
          queryParams: {
            'partner': this.router.routerState.snapshot.root.queryParamMap.get('partner')
          },
          queryParamsHandling: 'merge',
        }
        );

        await this.initPartner();
      })
    );

    await firstValueFrom(this.router.routerState.root.queryParams);
  }

  async ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async showOtherOptions() {
    this.partner = false;
    await this.navCtrl.navigateRoot([], {
      queryParams: {
        'partner': null,
      },
      queryParamsHandling: 'merge',
    })
  }

}
