import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2Mixpanel, Angulartics2Intercom, Angulartics2GoogleAnalytics } from 'angulartics2';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular/standalone';
import { Title } from '@angular/platform-browser';
import { User } from '../models/user.model';
import { User as FirebaseUser } from "@angular/fire/auth";
import { logEvent, setUserId, setUserProperties } from "@angular/fire/analytics";
import { doc, getDoc } from "@angular/fire/firestore";
import { ModelProvider } from '../models/general/model.provider';

/*
  Generated class for the AnalyticsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable(
  { providedIn: 'root' }
)
export class AnalyticsProvider {

  public currentScreen: string;
  public screenProperties: object;

  private validIntercomEvents = {
    'Game list / page opened': {},
    'Game list / new design button clicked': {
      updateAfter: true
    },
    'Task list / new task icon clicked': {
      updateAfter: true
    },
    'Task list / Simulator / start button clicked': {
      updateAfter: true
    },
    'Game list / Publish popup / save button clicked': {
      filterParams: {
        label: 'Published'
      },
    },
    'Game edit / Publish popup / save button clicked': {
      filterParams: {
        label: 'Published'
      },
    },
    'Game list / Game card / Invite URL button clicked': {},
    'Play list / page opened': {},
    'Game list overview / page opened': {},
    'Game details / page opened': {},
    'Game details / start game button clicked': {},
    'Game details / Role selection / selected': {},
    'App / loaded': {},

    'Sign in / Partner / page opened': {},
    'Sign in / Partner / login button clicked': {},
    'Play details / invite button clicked': {},
    'Join with code / submit button clicked': {},

    'Task list / page opened': {},
    'Task view / page opened': {},
    'Task view / Submit clicked': {},
    'Task view / upload finished': {},
    'Task view / Player finished': {},
    'My profile / logout button clicked': {},

    'Task editor / Quote answer / Input changed': {
      updateAfter: true
    },
    'Task editor / Preconditions / Add button clicked': {
      updateAfter: true
    },

    'Organise play / page opened': {
      updateAfter: true
    },
    'Organise play / Start Game button clicked': {},
    'Play monitoring / page opened': {},

    'Analytics / page opened': {},

    'Team / Pricing / page opened': {},
    'Team / Purchase / page opened': {},
    'Team / Purchase confirmation / page opened': {},
    'Team / Manage / Profile / page opened': {},
    'Team / Manage / Members / page opened': {},
    'Team / Manage / Plan details / page opened': {},
    'Team / Manage / Billing / page opened': {},
    'Team / Create Workspace button clicked': {},

    'Play options / page opened': {
      updateAfter: true
    },
    'Play options / Organized session / create button': {},
    'Play options / Instant session / copy url button clicked': {
      updateAfter: true
    },
    'Play options / Publish / first update button clicked': {},
    'Play options / Publish / update button clicked': {},
  };

  private validFirebaseAnalyticsEvents = {
    'Game list / page opened': {
      actionToLog: 'game_list_view'
    },
    'Game list / new design button clicked': {
      actionToLog: 'game_create'
    },
    'Task list / new task icon clicked': {
      actionToLog: 'task_create'
    },
    'Task list / Simulator / start button clicked': {
      actionToLog: 'simulator_start'
    },
    'Game list / Publish popup / save button clicked': {
      actionToLog: 'game_published',
      filterParams: {
        label: 'Published'
      },
    },
    'Game edit / Publish popup / save button clicked': {
      actionToLog: 'game_published',
      filterParams: {
        label: 'Published'
      },
    },
    'Game list / Game card / Invite URL button clicked': {
      actionToLog: 'game_invite_url_copied'
    },
    'Play list / page opened': {
      actionToLog: 'play_list_view'
    },
    'Game list overview / page opened': {
      actionToLog: 'game_list_view'
    },
    'Game details / page opened': {
      actionToLog: 'game_view'
    },
    'Game details / start game button clicked': {
      actionToLog: 'game_join_init'
    },
    'Game details / Role selection / selected': {
      actionToLog: 'game_join_role_selected'
    },
    'App / loaded': {
      actionToLog: 'app_loaded'
    },
    'Task list / page opened': {
      actionToLog: 'task_list_view'
    },
    'Task view / page opened': {
      actionToLog: 'task_view'
    },
    'Task view / Submit clicked': {
      actionToLog: 'task_submit'
    },
    'Task view / upload finished': {
      actionToLog: 'task_uploaded'
    },
    'Task view / Player finished': {
      actionToLog: 'play_finished'
    },
    'My profile / logout button clicked': {
      actionToLog: 'logout'
    }
  };

  constructor(
    public firebaseAnalytics: FirebaseAnalytics,
    private angulartics2: Angulartics2,
    private mixpanel: Angulartics2Mixpanel,
    private googleAnalytics: Angulartics2GoogleAnalytics,
    private intercom: Angulartics2Intercom,
    private platform: Platform,
    private titleService: Title,
    private modelProvider: ModelProvider
  ) {
  }

  public startTracking() {
    console.debug('analytics - startTracking');
    this.mixpanel.startTracking();
    this.googleAnalytics.startTracking();
    this.intercom.startTracking();
    if (this.platform.is('cordova')) {
      this.firebaseAnalytics.setEnabled(true);
    }
  }

  public async setCurrentScreen(name: string, properties?: object) {
    this.titleService.setTitle('Gamoteca ' + environment.title + ' - ' + name);
    console.debug('analytics - setCurrentScreen', name);
    this.currentScreen = name;
    this.screenProperties = properties;

    try {
      if (this.platform.is('cordova')) {
        await this.firebaseAnalytics.setCurrentScreen(name);
      } else {
        logEvent(this.modelProvider.fsAnalytics, 'screen_view', {
          firebase_screen: name, 
          firebase_screen_class: name
        });
      }
      await this.event(['page opened'], null, null, null, { setScreen: true });
    } catch (error) {
      console.error(error);
    }

  }

  public async error(error: string, code?: number) {
    this.firebaseAnalytics.logEvent('error', {
      message: error,
      code: code
    });
    this.angulartics2.exceptionTrack.next({
      message: error,
      code: code
    });
  }

  public async event(action: string | string[], label?: string, _properties?: Object, category?: string, options?: { setScreen: boolean }) {
    try {
      action = this.formatAction(action);
      const properties: any = { ...this.screenProperties, ..._properties };
      if (category) {
        properties.category = category;
      } else {
        if (this.currentScreen) {
          properties.category = this.currentScreen;
        }
      }
      if (label) {
        properties.label = label;
      }

      const appNameUppercase = environment.app.charAt(0).toUpperCase() + environment.app.slice(1);
      const actionToLog = appNameUppercase + ' / ' + properties.category + ' / ' + action;

      this.mixpanel.eventTrack(actionToLog, properties);
      this.googleAnalytics.eventTrack(actionToLog, properties);

      const intercomParam = this.validIntercomEvents[properties.category + ' / ' + action];
      if (
        intercomParam
      ) {
        const filterParams = intercomParam.filterParams;
        if (filterParams && !Object.entries(filterParams).every((([key, value]) => properties[key] === value))) {
          return;
        }
        this.intercom.eventTrack(intercomParam.actionToLog ? intercomParam.actionToLog : actionToLog, properties);

        if (intercomParam.updateAfter === true) {
          setTimeout(() => {
            (window as any).Intercom('update');
          }, 400);
        }
      }

      const fbParam = this.validFirebaseAnalyticsEvents[properties.category + ' / ' + action];
      if (fbParam) {
        const filterParams = fbParam.filterParams;
        if (filterParams && !Object.entries(filterParams).every((([key, value]) => properties[key] === value))) {
          return;
        }
        console.debug('firebaseParam.actionToLog:' + environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog));

        if (this.platform.is('cordova')) {
          await this.firebaseAnalytics.logEvent(environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog), this.objectToFirebaseValue(properties));
        } else {
          logEvent(this.modelProvider.fsAnalytics, environment.app + '_' + (fbParam.actionToLog ? fbParam.actionToLog : actionToLog), this.objectToFirebaseValue(properties));
        }
      }    
    } catch (error) {
      console.debug('analytics error');
      console.error(error);
    }

  }

  // Event name is too long. The maximum supported length is 40
  // Event name must contain only letters, numbers, or underscores
  private stringToFirebaseValue(name: string) {
    return name.replace(/-/g, '_').replace(/\//g, '').replace(/\s/g, '_').replace(/__/g, '_').replace(/___/g, '_');
  }
  private objectToFirebaseValue(object: Object) {
    const returnObject = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        returnObject[this.stringToFirebaseValue(key)] = value;
      }
    }

    return returnObject;
  }

  public systemEvent(action: string, category: string) {
    const properties: any = { ...this.screenProperties };

    properties.category = 'system/';
    if (category) {
      properties.category = 'system/' + category;
    }
    if (this.currentScreen) {
      properties.screen = this.currentScreen;
    }

    this.angulartics2.eventTrack.next({
      action: action,
      properties: properties,
    });

    this.firebaseAnalytics.logEvent(action, properties);
  }

  public async setUser(firebaseUser: FirebaseUser) {
    const userDocRef = doc(this.modelProvider.fsDB, 'users', firebaseUser.uid);
    const userSnapshot = await getDoc(userDocRef);
    const user: User = userSnapshot.data() as User;

    this.angulartics2.setUsername.next(firebaseUser.uid);

    this.intercom.setUserProperties({
      name: firebaseUser.displayName,
      user_id: firebaseUser.uid,
      created_at: new Date(firebaseUser.metadata.creationTime).getTime() / 1000,
    });

    this.mixpanel.setUserProperties({
      '$name': user.displayName,
      '$created': firebaseUser.metadata.creationTime
    });

    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (user.roles) {
      for (const role in user.roles) {
        if (Object.prototype.hasOwnProperty.call(user.roles, role)) {
          const roleItem = user.roles[role];
          if((window as any)?.mixpanel?.add_group) {
            (window as any)?.mixpanel?.add_group('Role', capitalize(role));
          }
        }
      }
    }

    if (this.platform.is('cordova')) {
      await this.firebaseAnalytics.setUserId(firebaseUser.uid);
    } else {
      setUserId(this.modelProvider.fsAnalytics, firebaseUser.uid);
    }
  }

  public async setUserProperties(properties: Object) {
    try {
      setUserProperties(this.modelProvider.fsAnalytics, this.objectToFirebaseValue(properties));
      if (this.platform.is('cordova')) {
        for (const name in properties) {
          if (properties.hasOwnProperty(name)) {
            const value = properties[name];

            await this.firebaseAnalytics.setUserProperty(this.stringToFirebaseValue(name), JSON.stringify(value));
          }
        }
      } else {
        setUserProperties(this.modelProvider.fsAnalytics, this.objectToFirebaseValue(properties));
      }
    } catch (error) {
      console.error(error);
    }
    this.angulartics2.setUserProperties.next(properties);
    if ((properties as any).email) {
      this.mixpanel.setUserProperties({
        $email: (properties as any).email
      });
    }
  }

  public logout() {
    if (this.platform.is('cordova')) {
      this.firebaseAnalytics.setUserId(null);
    } else {
      setUserId(this.modelProvider.fsAnalytics, null);
    }

    (<any>window).mixpanel.reset();
    (<any>window).ga('send', 'pageview', { 'sessionControl': 'start' });
    localStorage.removeItem('GA_LOCAL_STORAGE_KEY');
  }

  formatAction(action: string | string[]) {
    return typeof action === "string" ? action : (action as string[]).join(' / ')
  }

}
