import { doc, Query, collection, setDoc, updateDoc, deleteDoc, query, QueryConstraint, DocumentReference, Timestamp, QueryDocumentSnapshot, CollectionReference, DocumentSnapshot, onSnapshot } from '@angular/fire/firestore';
import { cloneDeep, isEqualWith, IsEqualCustomizer, PropertyName} from 'lodash-es';
import { shareReplay } from 'rxjs/operators';
import { ModelProvider } from '../models/general/model.provider';
import { Model } from '../models/general/model';
import { Observable } from 'rxjs';

export class FirestoreModel<T> extends Model {

  public id: string;
  private _storedFields: any = []; // Purpose: store only changed values
  protected _documentRef: DocumentReference;
  public isNew: Boolean;
  public createdAt: Timestamp;
  public createdBy: DocumentReference;
  public updatedAt: Timestamp;
  public updatedBy: DocumentReference;
  // filled in findAllBy only. should be added globally if other queries requires it
  public snapshot: DocumentSnapshot;

  public queries: {
    [id: string]: Observable<FirestoreModel<Model>> | Observable<FirestoreModel<Model>[]>;
  } = {};

  constructor(private _path: string, data: any, public options: any, protected modelProvider: ModelProvider) {
    super(data, { skipSetFields: true });

    // Trim "/" characters form the beginging and end
    this._path = this._path.trim().replace(/^\/+|\/+$/g, '');

    // Set whether the instance is new or not based on the path (odd number of segment)
    const isCreateID = !!(this._path.split('/').length % 2);
    // Add ID if new document instantiated
    if (isCreateID && this._path.length > 0) {
      //just creating unique id
      const collectionRef = collection(this.modelProvider.fsDB, "plans"); 
      this.id = doc(collectionRef).id;
      this._path = this._path + '/' + this.id;
    } else {
      this.id = this._path.split('/').splice(-1).pop();
    }

    this.isNew = (isCreateID || (options && options.isNew)) ? options.isNew : false;

    this.setFields(data);

    if (!this.isNew) {
      if (options?.snapshot) {
        this.snapshot = options.snapshot;
        this._storedFields = (options.snapshot as QueryDocumentSnapshot<T>).data();
      } else {
        this._storedFields = cloneDeep(data);
      }
    }
  }

  public getPath() {
    if (!this._path || this._path === '') {
      return this['COLLECTION_NAME'];
    }
    return this._path;
  }

  public getDocument(): DocumentReference {
    return doc(this.modelProvider.fsDB, this._path);
  }

  public get ref(): DocumentReference {
    return this.getReference();
  }

  public getReference(): DocumentReference {
    return this.getDocument();
  }

  public async save() {
    const self = this;

    if (this.isNew) {
      this.createdAt = Timestamp.now();
      this.createdBy = doc(this.modelProvider.fsDB, 'users', this.modelProvider.fsAuth.currentUser?.uid || '');
      this.updatedAt = this.createdAt;
      this.updatedBy = this.createdBy;
      const createFields = this._getChangedFields();
      return await setDoc(this.getReference(), createFields, { merge: true })
        .then(() => {
          self.isNew = false;
          this.storedFields = { ...this.storedFields, ...cloneDeep(createFields) };
        });
    } else {
      this.updatedAt = Timestamp.now();
      this.updatedBy = doc(this.modelProvider.fsDB, 'users', this.modelProvider.fsAuth.currentUser?.uid || '');
      const updateFields = this._getChangedFields();
      return await updateDoc(this.getReference(), updateFields).then(() => {
        this.storedFields = { ...this.storedFields, ...cloneDeep(updateFields) };
      });
    }
  }

  public create(path: string, data: any, options: { withModelData: boolean } = { withModelData: true }) {
    const instance = this.instantiate(path, data, { skipSetFields: true, isNew: true });
    if (options && options.withModelData) {
      instance.setModelFields(data);
    } else {
      instance.setFields(data);
    }

    instance.setDefaultValues();

    return instance;
  }

  public remove(): Promise<void> {
    return deleteDoc(this.getDocument());
  }

  public getChangedFields() {
    return this._getChangedFields();
  }

  // tslint:disable-next-line: max-line-length
  private changeFieldComparator: IsEqualCustomizer = (value: any, other: any, indexOrKey: PropertyName | undefined, parent: any, otherParent: any, stack: any): boolean | undefined => {
    if (value && other && typeof value.isEqual === 'function' && typeof other.isEqual === 'function') {
      return value.isEqual(other);
    }
    if (value && other && value.constructor && value.constructor.name === 'DocumentReference' && other.constructor && other.constructor.name === 'DocumentReference') {
      return (value as DocumentReference).path === (other as DocumentReference).path;
    }
    return;
  }

  public get storedFields() {
    return this._storedFields;
  }

  public set storedFields(values) {
    this._storedFields = values;
  }

  private _getChangedFields(): object | null {
    const changed = {};

    const rules = this.getRules();
    for (const propertyName in rules) {
      if (rules.hasOwnProperty(propertyName)) {
        if (this[propertyName] && this[propertyName].toData) {
          // tslint:disable-next-line: max-line-length
          if (!isEqualWith(this[propertyName].toData(), this._storedFields[this.getFieldNameByPropertyName(propertyName)], this.changeFieldComparator)) {
            changed[this.getFieldNameByPropertyName(propertyName)] = this[propertyName].toData();
          }
        } else {
          if (!isEqualWith(this[propertyName], this._storedFields[propertyName], this.changeFieldComparator)) {
            changed[this.getFieldNameByPropertyName(propertyName)] = this[propertyName];
          }
        }
      }
    }

    return (Object.keys(changed).length > 0) ? changed : null;
  }

  protected getRules() {
    return {
      createdAt: {},
      createdBy: {},
      updatedAt: {},
      updatedBy: {}, ...super.getRules()
    };
  }

  public resetToStored() {
    if (this.snapshot) {
      this.setFields(this.snapshot.data());
      return;
    }

    this.setFields(this._storedFields);
  }

  public findByRef(ref: DocumentReference | string, options: { updateSelfFields: boolean } = { updateSelfFields: true }): Observable<T | null> {
    let path = typeof ref === 'string' ? ref : ref.path;

    const docRef = doc(this.modelProvider.fsDB, path);

    if (this.modelProvider.cache.getModel(docRef)) {
        return this.modelProvider.cache.getModel(docRef);
    }
    let instance: FirestoreModel<T> & T;

    const query = new Observable<T | null>((observer) => {
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                if (options.updateSelfFields) {
                    if (instance) {
                        instance.setFields(snapshot.data());
                        instance.snapshot = snapshot;
                    } else {
                        instance = this.instantiate(snapshot.ref.path, snapshot.data(), { snapshot });
                    }
                    observer.next(instance);
                } else {
                    observer.next(this.instantiate(snapshot.ref.path, snapshot.data(), { snapshot }));
                }
            } else {
                observer.next(null);
            }
        });

        return () => unsubscribe(); 
    }).pipe(shareReplay(1));

    this.modelProvider.cache.models.set(docRef, query);

    return query;
  }

  public findByID(_id: string) {
    return this.findByRef(doc(this.modelProvider.fsDB, this.getPath(), _id));
  }

  public findAllBy(queryFn?: QueryConstraint[], _collectionRef?: CollectionReference, que?: Query): Observable<Array<T>> {
    let collectionRef: CollectionReference;
    let q = que;
    if(!que){
      collectionRef = _collectionRef ?
      collection(this.modelProvider.fsDB, _collectionRef.path) :
      collection(this.modelProvider.fsDB, this.getPath());
      q = query(collectionRef, ...(queryFn || []));
    }

    if (this.modelProvider.cache.getQuery(q)) {
      return this.modelProvider.cache.getQuery(q);
    }

    const res =  new Observable<Array<T>>((observer) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const models: T[] = [];
        querySnapshot.forEach((doc) => {
          const model = this.instantiate(doc.ref.path, doc.data(), { snapshot: doc });
          model.snapshot = doc;
          models.push(model);
        });
        observer.next(models);
      }, (error) => {
        observer.error(error); 
      });
      return () => unsubscribe();
    }).pipe(
      shareReplay(1)
    );

    this.modelProvider.cache.setQuery(q, res);
    return res;
  }

  protected instantiateClass(modelClass: Model, data: any, options: any = {}) {
    return (modelClass as any).instantiate(data, this, options, this.modelProvider);
  }

  protected instantiate(path, data: any, options?: any): FirestoreModel<T> & T {
    throw new Error(this.constructor.name + ' Instantiate implementation required!');
  }
}
