
import { Model } from '../models/general/model';
import { ModelProvider } from '../models/general/model.provider';
import { FirestoreModel } from './firestore-model';

export class FirestoreArrayField<T> {
  public items: Array<T>;

  public push;
  public pop;
  public filter;
  public splice;
  public forEach;
  public map;
  public indexOf;
  public find;
  public get length() { return this.items.length; }
  public set length(length) { this.items.length = length; }

  public constructor(
    data: Array<any>,
    protected documentInstance?: Model,
    protected options?: { skipSetFields?: true },
    protected modelProvider?: ModelProvider
  ) {
    // To reach model provider
    if (!options || !options.skipSetFields) {
      this.setFields(data);
    }

    this.push = this.items && this.items.push.bind(this.items);
    this.pop = this.items && this.items.pop.bind(this.items);
    this.filter = this.items && this.items.filter.bind(this.items);
    this.splice = this.items && this.items.splice.bind(this.items);
    this.forEach = this.items && this.items.forEach.bind(this.items);
    this.map = this.items && this.items.map.bind(this.items);
    this.indexOf = this.items && this.items.indexOf.bind(this.items);
    this.find = this.items && this.items.find.bind(this.items);
  }

  public setFields(data: Array<any>) {
    this.items = [];
    this.push = this.items.push.bind(this.items);
    this.pop = this.items.pop.bind(this.items);
    this.filter = this.items.filter.bind(this.items);
    this.splice = this.items.splice.bind(this.items);
    this.forEach = this.items.forEach.bind(this.items);
    this.map = this.items.map.bind(this.items);
    this.indexOf = this.items.indexOf.bind(this.items);
    this.find = this.items.find.bind(this.items);
    data.forEach(itemData => {
      const item = (this.options as any).mapToClass.instantiate(
        itemData,
        this.documentInstance,
        this.options,
        this.modelProvider
      );

      this.items.push(item);
    });
  }

  public toData() {
    const data = new Array<any>();
    this.items.forEach((item: T) => {
      data.push((item as any).toData());
    });

    return data;
  }

  removeBy(predicate: (value: T) => boolean) {
    for (let i = this.items.length; i--;) {
      if (predicate(this.items[i])) {
        this.items.splice(i, 1);
      }
    }
  }

  // tslint:disable-next-line: member-ordering
  public static instantiate(data: any, model: FirestoreModel<any>, options: any, modelProvider: ModelProvider) {
    return new FirestoreArrayField(data, model, options, modelProvider);
  }
}
