<ion-content>
  <ion-grid class="h-100">
    <ion-row  class="ion-justify-content-center ion-align-items-center h-100">
      <ion-col size-lg="4" size-md="5" size-sm="6" class="welcome-card ion-text-center {{'lang-' + language}}" [class.partner]="partnerProvider">
        <ng-container *ngIf="partner && partnerProvider">
          <h1 class="text-center">
            <img src="assets/pages/welcome/logo.avif" class="logo partnered mb-5" alt="logo" />
          </h1>
          <img *ngIf="partnerProvider.logoURL" [src]="partnerProvider.logoURL" [alt]="partnerProvider.title" class="partner-logo d-block mb-4 mx-auto" />
          <ion-button [disabled]="processing" (click)="doLoginMoodle(partnerProvider)" shape="round" color="secondary" class="kaya">
            <ion-spinner *ngIf="processing" name="lines-small"></ion-spinner>
            {{'welcome/sign-in/with-button/partner/label' | translate: {title: partnerProvider.title} }}
          </ion-button>
          <p class="mt-5">
            {{'welcome/sign-in/other-options/prefix' | translate}} <a (click)="showOtherOptions()">{{'welcome/sign-in/other-options/label' | translate}}</a>
          </p>
        </ng-container>
        <ng-container *ngIf="partner === false">
          <h1 class="text-center">
            <img src="assets/pages/welcome/logo.avif" class="logo" alt="logo" />
          </h1>

          <ion-button color="secondary" shape="round" expand="block" class="text-left label-btn" (click)="doLoginGoogle()" [disabled]="processing">
            <span class="icon-background"></span>
            <ion-spinner *ngIf="actual === 'google' && processing === true" slot="start"></ion-spinner>
            <ion-icon *ngIf="actual !== 'google' || processing !== true" name="logo-google" slot="start">
            </ion-icon>
            <span> {{'welcome/sign-in/with-button/google' | translate : {title: 'Google'} }} </span>
          </ion-button>
  
          <ion-button color="secondary" shape="round" expand="block" class="text-left label-btn" (click)="doLoginFB()" [disabled]="processing">
            <span class="icon-background"></span>
            <ion-spinner *ngIf="actual === 'facebook' && processing === true" slot="start"></ion-spinner>
            <ion-icon *ngIf="actual !== 'facebook' || processing !== true" name="logo-facebook" slot="start">
            </ion-icon>
            <span> {{'welcome/sign-in/with-button/facebook' | translate : {title: 'Facebook'} }} </span>
          </ion-button>
  
          <ion-button *ngIf="isAppleSignInAvailable || true" (click)="doLoginApple()"
          [disabled]="processing"
          class="text-left label-btn"
          color="secondary" shape="round" expand="block"> 
            <span class="icon-background"></span>
            <ion-spinner *ngIf="actual === 'apple' && processing === true" slot="start"></ion-spinner>
            <ion-icon *ngIf="actual !== 'apple' || processing !== true" name="logo-apple" slot="start"></ion-icon>
            <span>{{'welcome/sign-in/with-button/apple' | translate }}</span>
          </ion-button>
  
          <ion-button color="secondary" shape="round" expand="block" class="text-left label-btn mail" (click)="login()" [disabled]="processing">
            <span class="icon-background email"></span>
            <ion-icon name="mail-sharp" slot="start"></ion-icon>
            <span>{{'welcome/sign-in/with-button/email' | translate }}</span>
          </ion-button>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>