import { FirestoreModel } from '../firebase/firestore-model';
import { firstValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { DocumentReference, Timestamp, doc, refEqual } from '@angular/fire/firestore';
import { Model } from './general/model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Workspace } from './workspace.model';
import { OrganizationMember, OrganizationMemberLevel } from './organization-members.model';
import { Plan } from './plan';
import { ImageWithSizes } from './general/image-with-sizes';
import { ref as storageRef, updateMetadata } from '@angular/fire/storage';

export type OrganizationLevel = 'owner' | 'admin' | 'billing';

export type PlanStatus = 'trialing' | 'active' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'canceled' | 'unpaid';

export class Organization extends FirestoreModel<Organization> {
  public COLLECTION_NAME = 'organizations';

  public title: string;
  
  _image: ImageWithSizes;
  public get imageUrl() {
    return this._image && this._image.getSizedUrl('576x') ? this._image.getSizedUrl('576x') : this.photoURL;
  }
  public set imageUrl(url: string) {
    this._image.setSizedUrl(url);
  }

  public plan: {
    ref: DocumentReference,
    status: PlanStatus;
    currentPeriodStart: Timestamp;
    currentPeriodEnd: Timestamp;
    cancelAt: Timestamp;
    collectionMethod: 'charge_automatically' | 'send_invoice';
  };
  public stripe: {
    customerID: string,
    priceID: string,
    subscriptionID: string
  };
  public integrations: {
    providerID: string,
    sso: boolean,
    lti: boolean
  }
  public color: {
    primary: string,
    warning: string,
  }

  protected rules() {
    return {
      title: {},
      photoURL: {},
      _image: { 
        [Model.RULE_ALIAS]: 'image',
        [Model.RULE_CLASS]: ImageWithSizes,
        [Model.RULE_DEFAULT]: () => new ImageWithSizes({}, this, {}, this.modelProvider)
      },
      plan: {
        [Model.RULE_DEFAULT]: {
          status: 'active'
        }
      },
      stripe: {
        [Model.RULE_DEFAULT]: {}
      },
      integrations: {},
      color: {}
    };
  }

  public get isActive() {
    return ['active', 'trialing'].includes(this.plan.status);
  }

  /**
   * Workspaces
   */
  // tslint:disable-next-line: member-ordering
  private _workspaces$: ReplaySubject<Workspace[]>;
  public get workspaces$(): Observable<Workspace[]> {
    if (this._workspaces$ !== undefined) { return this._workspaces$; }

    this._workspaces$ = new ReplaySubject<Workspace[]>(1);
    this.modelProvider.workspace.findAllByOrganization(this).subscribe(this._workspaces$);
    return this._workspaces$;
  }

  // tslint:disable-next-line: member-ordering
  private _workspaces: Array<Workspace>;
  public get workspaces(): Array<Workspace> {
    if (this._workspaces !== undefined) { return this._workspaces; }
    this._workspaces = [];

    this.workspaces$.subscribe(workspaces => {
      this._workspaces = workspaces;
    });
    return this._workspaces;
  }

  /**
   * Package
   */
  // tslint:disable-next-line: member-ordering
  private _plan$: Observable<Plan>;
  public get plan$(): Observable<Plan> {
    if (this._plan$ !== undefined) { return this._plan$; }

    this._plan$ = this.modelProvider.plan.findByRef(this.plan.ref);
    return this._plan$;
  }

  /**
   * Members
   */
  // tslint:disable-next-line: member-ordering
  private _members$: ReplaySubject<OrganizationMember[]>;
  public get members$(): Observable<OrganizationMember[]> {
    if (this._members$ !== undefined) { return this._members$; }

    this._members$ = new ReplaySubject<OrganizationMember[]>(1);
    this.modelProvider.organizationMember.findAllByOrganization(this).subscribe(this._members$);
    return this._members$;
  }

  private _myMember$: Observable<OrganizationMember>;
  public get myMember$() {
    if (this._myMember$) { return this._myMember$; }

    if(this.isOrphan()) {
      this._myMember$ = of(null);
      return this._myMember$;
    }
    const ref = doc(this.modelProvider.fsDB, `${this.ref.path}/members/${this.modelProvider.user.meReference.id}`);
    this._myMember$ = this.modelProvider.organizationMember.findByRef(ref).pipe(catchError(_ => of(null)));

    return this._myMember$;
  }

  public async createFreeOrganization(title: string, email:string) {
    return await this.modelProvider.callFunction('organizationCreateFree',{
      organizationName: title,
      email
    });
  }

  public async inviteMember(email: string, level: OrganizationMemberLevel, isSendEmail = true) {
    return await this.modelProvider.callFunction('organizationInviteMember',{
      email: email,
      level: level,
      organizationPath: this.ref.path,
      sendEmail: isSendEmail
    });
  }

  public async cancelSubscription() {
    return await this.modelProvider.callFunction('organizationCancelSubscription',{
      organizationPath: this.ref.path
    });
  }

  public async reactivateSubscription() {
    return await this.modelProvider.callFunction('organizationReactivateSubscription',{
      organizationPath: this.ref.path
    });
  }

  protected instantiate(path, data, options?: any) {
    return new Organization(path, data, options, this.modelProvider);
  }

  uploadFile(category: string, file: File, metadata?: any, extras?: any) {
    const reference = storageRef(this.modelProvider.fsStorage,this.getStorageReferencePath(category, file, extras));
    updateMetadata(reference, { customMetadata: metadata || {} });
    return {
      ref: reference,
      uploadTask: reference
    };
  }

  getStorageReferencePath(category: string, file: File, extras?: any) {
    let fileName = file.name;
    if (extras && extras.fileName) {
      const re = /(?:\.([^.]+))?$/;
      const extension = re.exec(file.name)[1];   // "txt"
      fileName = extras.fileName + '-' + Date.now() + "." + extension;
    }
    return this.getDocument().path + '/' + category + '/' + fileName;
  }

  hasReference(workspace: Workspace) {
    return workspace && this.getReference().id === workspace.getReference().id;
  }

  private _photoURL: string;
  get photoURL(): string {
    return this._photoURL ?
      this._photoURL :
      '/assets/images/img-placeholder.avif';
  }

  set photoURL(value: string) {
    this._photoURL = value;
  }

  async isValidWorkspaceLimit(toCreate = true) {

    if (!['active', 'trialing'].includes(this.plan.status)) { return false; }

    const user = await this.modelProvider.user.getMe();

    const workspaces = await firstValueFrom(
      user.workspaces$
      .pipe(mergeMap(async workspaces => {
        return await this.modelProvider.asyncFilter(workspaces, async workspace => 
          (await workspace.getMyMember()).level !== 'player'
        );
      }))
      .pipe(map(workspaces => {
        return workspaces.filter(workspace => {
          if (workspace.ownerOrganizationRef) {
            return refEqual(workspace.ownerOrganizationRef, this.ref);
          } else {
            return this.isOrphan();
          }
        });
      })));

    const plan = await firstValueFrom(this.plan$);
    if (plan.features.workspaceLimit < 0) { return true; }

    return (workspaces.length + (toCreate ? 1 : 0)) <= plan.features.workspaceLimit;
  }
  public orphanRef = doc(this.modelProvider.fsDB, 'organizations', 'orphan');
  isOrphan() {
    return refEqual(this.ref, this.orphanRef);
  }

  getOrphanOrganization() {
    const model = this.modelProvider.organization.create(this.modelProvider.organization.orphanRef.path, {
      title: 'Personal Workspace',
      plan: {
        ref: doc(this.modelProvider.fsDB, 'plans', 'free'),
        status: 'active'
      }
    })

    return model;
  }

}
