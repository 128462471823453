import { Component, NgZone } from '@angular/core';
import { Platform, IonApp, IonRouterOutlet, AlertController } from '@ionic/angular/standalone';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { environment } from 'src/environments/environment';
import { filter, first } from 'rxjs/operators';
import { AppAnalyticsService } from './app.analytics';
import { Title, Meta } from '@angular/platform-browser';
import { NavController } from '@ionic/angular/standalone';
import { BrowserRecommendationService } from './core/browser-recommendation/browser-recommendation.service';
import { CloudMessagingService } from './core/cloud-messaging/cloud-messaging.service';
import { Storage } from '@ionic/storage-angular';
import { BrandingService } from './core/branding/branding.service';
import { OrganizationService } from './organization/organization.service';
import { combineLatest, firstValueFrom } from 'rxjs';
import { AppUpdateService } from './core/app-update/app-update.service';
import { applyActionCode } from '@angular/fire/auth';
import { RemoteConfig } from '@angular/fire/remote-config';
import { ModelProvider } from './core/models/general/model.provider';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    // tslint:disable-next-line: use-host-property-decorator
    host: { 'class': 'app-' + environment.app },
    providers: [
        FirebaseDynamicLinks,
        AppAnalyticsService,
        AuthService,
        Platform,
        FirebaseMessaging,
        CloudMessagingService,
        OrganizationService,
        AndroidPermissions,
        Diagnostic
    ],
    imports: [IonRouterOutlet, IonApp]
})
export class AppComponent {

  direction = 'ltr';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private dynamicLinks: FirebaseDynamicLinks,
    private router: Router,
    private auth: AuthService,
    private ngZone: NgZone,
    private browserRecommendation: BrowserRecommendationService,
    private firebaseMessaging: FirebaseMessaging,
    private androidPermission: AndroidPermissions,
    private titleService: Title,
    private metaService: Meta,
    private navCtrl: NavController,
    private remoteConfig: RemoteConfig,
    private cloudMessaging: CloudMessagingService,
    private storage: Storage,
    private brandService: BrandingService,
    private organizationService: OrganizationService,
    private appUpdateService: AppUpdateService,
    private modelProvider: ModelProvider,
    private alertCtrl: AlertController,
    private diagnostic: Diagnostic
  ) {
    this.platform.resume.subscribe(() => {
      this.checkAndStartListening(); // Check and start listening when app resumes
    });
  }

  async ngOnInit() {

    await Promise.all([
      this.initializeApp(),
      this.initTranslate(),
      this.initializeDynamicLink(),
      this.initializeMessaging(),
      this.initBrowserRecommendation(),
      this.initBrand(),
      this.titleService.setTitle('Gamoteca ' + environment.title)
    ]);

    if (environment.app === 'player') {
      this.metaService.addTags([
        { name: 'apple-itunes-app', content: 'app-id=' + environment.iOS.playAppId + ', app-argument=' + location.href }
      ])
    }
  }

  private async checkAndStartListening() {
    if (this.platform.is('android')) {
      const res = await this.androidPermission.checkPermission(this.androidPermission.PERMISSION.POST_NOTIFICATIONS);
      if (res.hasPermission) {
        console.log("Android Notification Permission Granted!");
        this.listenToMessages();
      }
    } else if (this.platform.is('ios')) {
      this.diagnostic.getRemoteNotificationsAuthorizationStatus().then(async (status) => {
        if (status === 'authorized') { 
          console.log("iOS Notification Permission Granted!");
          this.listenToMessages();
        } else {
          console.log("iOS Notification Permission Not Granted.");
        }
      }).catch(err => console.error("Error checking iOS notification permission:", err));
    }
  }

  private async initializeMessaging() {
    await this.platform.ready();
    if (this.platform.is('cordova')) {
      await this.setupMobileMessaging();
    } else {
      await this.setupWebMessaging();
    }
  }

  // Common function to listen to both foreground & background messages
  private listenToMessages() {
    this.firebaseMessaging.onMessage().subscribe({
      next: (data) => {
        console.log("Foreground Notification Received:", data);
        if (data.redirectURL) {
          this.router.navigateByUrl(data.redirectURL);
        }
      },
      error: (error) => console.error("Error receiving foreground message:", error),
    });

    this.firebaseMessaging.onBackgroundMessage().subscribe({
      next: (data) => {
        console.log("Background Notification Received:", data);
        if (data.redirectURL) {
          this.router.navigateByUrl(data.redirectURL);
        }
      },
      error: (error) => console.error("Error receiving background message:", error),
    });
  }
  
  // Android setup with notification permissions
  private async setupMobileMessaging() {
    if (this.platform.is('android')) {
      const res = await this.androidPermission.requestPermission(this.androidPermission.PERMISSION.POST_NOTIFICATIONS);
      if (res.hasPermission) {
        this.listenToMessages();
      }else{
        this.showEnableNotificationAlert();
      }
    } else if (this.platform.is('ios')) {
      const permission = await this.firebaseMessaging.requestPermission({ forceShow: true });
      if (permission === 'granted') {
        this.listenToMessages();
      } else {
        this.showEnableNotificationAlert();
      }
    }
  }

  // Web-based FCM token handling
  private async setupWebMessaging() {
    this.auth.user$.pipe(filter(user => !!user), first()).subscribe(async user => {
      try {
        const hasToken = await this.cloudMessaging.hasToken();
        if (!hasToken) {
          const token: any = await this.cloudMessaging.requestPermission();
          await this.cloudMessaging.storeToUser(token);
        }
      } catch (error) {
        console.log("Error requesting FCM token:", error);
      }
    });
  }

  // Show an alert if the user denies notification permission
  private showEnableNotificationAlert() {
    this.alertCtrl.create({
      header: 'Notifications Disabled',
      message: 'Please enable notifications to get real-time updates on tasks and feedback during gameplay',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Open Settings',
          handler: () => {
            this.openAppSettings();
          },
        },
      ],
    }).then(alert => alert.present());
  }

  private openAppSettings() {
    this.diagnostic.switchToSettings();
  }

  private async initializeApp() {
    await this.storage.create();
    await this.platform.ready();

    if (this.platform.is('cordova')) {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      window.open = (window as any).cordova.InAppBrowser.open;
    }
  }

  async initializeDynamicLink() {
    await this.platform.ready();

    const handleMoodlePartner = (url: string) => {  
      const parsedURL = this.parseURL(url);
      if (parsedURL.searchObject) {
        const params = parsedURL.searchObject;
        const providerID = params.partner;
        const encryptedParams = params.partnervars;
        if(providerID && encryptedParams) {
          sessionStorage.setItem('partnerProviderID', providerID);
          sessionStorage.setItem('partnerEncryptedParams', encryptedParams);
        }
      }
    }

    const handleAnalytics = (url: string) => {
      const parsedURL = this.parseURL(url);
      if (parsedURL.searchObject) {
        const params = parsedURL.searchObject;
        const campaignName = params.utm_campaign || params.cn;
        if (campaignName) {
          (window as any).ga('set', 'campaignName', campaignName);
          (window as any).mixpanel.people.set_once({ utm_campaign: campaignName });
          (window as any).mixpanel.people.set({ "utm_campaign [last touch]": campaignName });
          (window as any).mixpanel.register({ "utm_campaign [last touch]": campaignName });

        }

        const campaignSource = params.utm_source || params.cs;
        if (campaignSource) {
          (window as any).ga('set', 'campaignSource', campaignSource);
          (window as any).mixpanel.people.set_once({ utm_source: campaignSource });
          (window as any).mixpanel.people.set({ "utm_source [last touch]": campaignSource });
          (window as any).mixpanel.register({ "utm_source [last touch]": campaignSource });
        }

        const campaignMedium = params.utm_medium || params.cm;
        if (campaignMedium) {
          (window as any).ga('set', 'campaignMedium', campaignMedium);
          (window as any).mixpanel.people.set_once({ utm_medium: campaignMedium });
          (window as any).mixpanel.people.set({ "utm_medium [last touch]": campaignMedium });
          (window as any).mixpanel.register({ "utm_medium [last touch]": campaignMedium });
        }

        const campaignContent = params.utm_content || params.cc;
        if (campaignContent) {
          (window as any).ga('set', 'campaignContent', campaignContent);
          (window as any).mixpanel.people.set_once({ utm_content: campaignContent });
          (window as any).mixpanel.people.set({ "utm_content [last touch]": campaignContent });
          (window as any).mixpanel.register({ "utm_content [last touch]": campaignContent });
        }

        const campaignID = params.ci;
        if (campaignID) {
          (window as any).ga('set', 'campaignId', campaignID);
        }

        const docReferrer = params.dr;
        if (docReferrer) { (window as any).ga('set', 'referrer', docReferrer); }
      }

    }

    const handleLink = async link => {
      const parsedURL = this.parseURL(link.deepLink);
      if (parsedURL.pathname.startsWith('/__/auth/action')) {
        const parsedContinueURL = this.parseURL(decodeURIComponent(parsedURL.searchObject.continueUrl));
        if (parsedURL.searchObject.mode === 'signIn' && parsedContinueURL.searchObject.verificationCode) {
          let emailVerified = false;
          applyActionCode(this.modelProvider.fsAuth, parsedContinueURL.searchObject.verificationCode).then(() => {
            emailVerified = true;
          });
          await this.auth.singInWithEmailLink(decodeURIComponent(parsedContinueURL.searchObject.email), link.deepLink).then((user) => {
            if (emailVerified) {
              this.auth.analytics.setUserProperties({ 'auth-email-verified': true });
              this.auth.analytics.event(['Email verified'], null, {}, 'Authentication');
            }
          }).catch((error) => {
            console.log('ERROR logged in via email link', error);
          });
        }
        this.ngZone.run(() => {
          const urlWithoutDomain = parsedURL.searchObject.continueUrl.replace(/^.*\/\/[^\/]+/, '');
          handleAnalytics(parsedURL.searchObject.continueUrl);
          handleMoodlePartner(parsedURL.searchObject.continueUrl);

          this.navCtrl.navigateRoot(urlWithoutDomain);
        });
        return;
      }

      const pathName = link.deepLink.replace(/^.*\/\/[^\/]+/, '');
      handleAnalytics(link.deepLink);
      handleMoodlePartner(link.deepLink);
      this.navCtrl.navigateRoot(pathName);
    }

    if (this.platform.is('cordova')) {
      const link = await this.dynamicLinks.getDynamicLink();
      if (link) { await handleLink(link) }
      this.dynamicLinks.onDynamicLink().subscribe(link => handleLink(link));
    } else {
      handleAnalytics(window.location.href)
      handleMoodlePartner(window.location.href);
      return true;
    }

  }

  private parseURL(url) {
    let parser: any = document.createElement('a'),
      searchObject: any = {},
      queries, split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      searchObject[split[0]] = split[1];
    }
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash
    };
  }

  async initTranslate() {
    // Set the default language for translation strings, and the current language.
    const user = await firstValueFrom(this.auth.user$);
    this.translate.setDefaultLang(user?.language || 'en');
    this.translate.addLangs(['en', 'es', 'fr', 'ar']);
    const browserLang = this.translate.getBrowserLang();

    if (browserLang) {
      if (browserLang === 'zh') {
        const browserCultureLang = this.translate.getBrowserCultureLang();

        if (browserCultureLang.match(/-CN|CHS|Hans/i)) {
          this.translate.use('zh-cmn-Hans');
        } else if (browserCultureLang.match(/-TW|CHT|Hant/i)) {
          this.translate.use('zh-cmn-Hant');
        }
      } else {
        this.translate.use(user?.language || this.translate.getBrowserLang());
      }
    } else {
      this.translate.use(user?.language || 'en'); // Set your language here
    }

    this.direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';

    this.auth.user$.pipe(filter(user => !!user),first()).subscribe(async user => {
      if(user.language) {
        this.translate.use(user.language);
        this.direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
      }  
    })
  }

  async initBrowserRecommendation() {
    if (
      environment.app === 'player' &&
      !this.browserRecommendation.platform.is('cordova') &&
      this.browserRecommendation.isSafari()
      && parseFloat(this.browserRecommendation.browser().version) < 14.1
    ) {
      await this.browserRecommendation.showModal();
    }
  }

  async initBrand() {
    await this.brandService.init();
    await this.brandService.setBrand('FpBM0aALYD9Tn7fu6ohC');
    combineLatest([this.auth.user$, this.organizationService.activeOrganization$])
    .subscribe(async ([user, organization]) => {
      if(user && organization) {
        await this.brandService.setBrand(organization.id);
      } else {
        await this.brandService.setBrand('FpBM0aALYD9Tn7fu6ohC');
      }
    })
  }
}
