import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { ModelProvider } from 'src/app/core/models/general/model.provider';
import { ModalController, Platform, IonButton, IonSpinner } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { doc } from '@angular/fire/firestore';

@Component({
    selector: 'app-organization-join',
    templateUrl: './organization-join.component.html',
    styleUrls: ['./organization-join.component.scss'],
    imports: [IonSpinner, IonButton, ReactiveFormsModule, FormsModule, NgIf, TranslateModule]
})
export class OrganizationJoinComponent{

  processing: string = null;

  @ViewChild("form") form: NgForm;

  @Input()
  organization: {
    title: string,
    imageURL?: string,
    path: string,
    id: string,
  }

  terms: boolean = false;
  @ViewChild('label') label: ElementRef;

  constructor(
    private organizationService: OrganizationService,
    private modelProvider: ModelProvider,
    private modalController: ModalController,
    private platform: Platform
  ) { }

  private linkFunction = (event: Event) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    window.open((event.target as any).getAttribute('href'), this.platform.is('android') ? '_system' : '_blank');
  }

  private _links: Array<Element> = [];
  ngAfterViewInit(): void {
    if (this.platform.is('cordova')) {
      this._links = this.label.nativeElement.querySelectorAll('a');
    }

    for (const link of this._links) {
      link.addEventListener('click', this.linkFunction)
    }
  }

  async submit() {
    try {
      this.processing = 'join';
      await this.organizationService.joinToOriganization(
        doc(this.modelProvider.fsDB, "organizations", this.organization.id),
      );
      await this.modalController.dismiss(true);
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      this.processing = null;
    }
  }
}
