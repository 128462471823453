import { Task } from './task.model';
import { DocumentReference } from '@angular/fire/firestore';
import { FirestoreField } from '../firebase/firestore-field';
import { ModelProvider } from './general/model.provider';

export class Rule extends FirestoreField {
  public taskRef: DocumentReference;

  layoutID: string;
  operator: string;
  value: string | boolean;
  required: boolean;

  public rules() {
    return {
      taskRef: {},
      layoutID: {},
      operator: {},
      required: {},
      value: {},
    };
  }

  public toData() {
    return {
      layoutID: (this.layoutID) ? this.layoutID : null,
      operator: this.operator,
      taskRef: this.taskRef,
      required: true,
      value: (this.value === 'false') ? false : this.value
    };
  }

  // tslint:disable-next-line: member-ordering
  public static instantiate(data: any, model: Task, options: any, modelProvider: ModelProvider): Rule {
    return new Rule(data, model, options, modelProvider);
  }

  public hasValue() {
    return this.operator === 'chosen' ? this.value !== null && this.value !== undefined : this.value === 'completed';
  }

  public isValid() {
    return this.taskRef && this.operator && this.hasValue();
  }
}
