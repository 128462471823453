import { Workspace, WorkspaceLevel } from './workspace.model';
import { DocumentReference, QueryConstraint, Timestamp, collectionGroup, collection, doc, where, query } from "@angular/fire/firestore";
import { Observable } from 'rxjs';
import { User } from './user.model';
import { FirestoreModel } from '../firebase/firestore-model';

export class WorkspaceMember extends FirestoreModel<WorkspaceMember> {
  active: boolean;
  level: WorkspaceLevel;
  userRef: DocumentReference;
  joinedAt: Timestamp;

  protected rules() {
    return {
      active: {},
      level: {},
      userRef: {},
      joinedAt: {}
    };
  }

  get workspaceRef() {
    return this.ref.parent.parent;
  }

  private _workspace$: Observable<Workspace>;
  public get workspace$() {
    if(this._workspace$) return this._workspace$;

    this._workspace$ = this.modelProvider.workspace.findByRef(this.workspaceRef);
    return this._workspace$;
  }

  public findAllByWorkspace(workspace: Workspace, queryConstraints?: QueryConstraint[]) {
    const wsMembersRef = collection(doc(this.modelProvider.fsDB, 'workspaces', workspace.id), 'wsMembers');
    return this.findAllBy(queryConstraints, wsMembersRef);
  }

  public findAllByUser$(user: User | DocumentReference, queryConstraints?: QueryConstraint[]): Observable<Array<WorkspaceMember>> {
    const userRef = (user instanceof User) ? user.ref : user;

    const wsMembersCollection = collectionGroup(this.modelProvider.fsDB, 'wsMembers'); 
    const constraints = [ where('active', '==', true), where('userRef', '==', userRef) , ...(queryConstraints || []) ];
    const queryRef = query(wsMembersCollection, ...constraints);

    return this.findAllBy(null, null, queryRef);
  }

  /**
   * User
   */
  private _user$: Observable<User>;
  public get user$(): Observable<User> {
    if (this._user$ !== undefined) { return this._user$; }

    this._user$ = this.modelProvider.user.findByRef(this.userRef);
    return this._user$;
  }

  async changeLevel(level: WorkspaceLevel) {
    return await this.modelProvider.callFunction('wsChangeMemberLevel', {
      level: level,
      memberPath: this.ref.path
    });
  }

  async deactivateUserAsMember() {
    return await this.modelProvider.callFunction('wsActivateDeactivateMember', {
      isActive: false,
      memberPath: this.ref.path
    });
  }

  async reactivateUserAsMember() {
    return await this.modelProvider.callFunction('wsActivateDeactivateMember', {
      isActive: true,
      memberPath: this.ref.path
    });
  }

  protected instantiate(path, data, options?: any) {
    return new WorkspaceMember(path, data, options, this.modelProvider);
  }

}
