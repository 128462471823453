import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ModelProvider } from '../core/models/general/model.provider';
import { Script } from '../core/models/script.model';
import { WorkspaceService } from '../core/workspace/workspace.service';
import { OrganizationService } from '../organization/organization.service';

@Injectable({
    providedIn: 'root',
})
export class ScriptResolver  {
    constructor(
        private modelProvider: ModelProvider,
        private workspaceService: WorkspaceService,
        private organizationService: OrganizationService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Script> | Observable<never> {
        const id = route.paramMap.get('scriptID');

        return new Observable<Script | never>((observer) => {
            this.modelProvider.script.findByID(id).pipe(first()).subscribe(async script => {
                this.workspaceService.setActiveWorkspaceByRef(script.workspaceRef);
                this.organizationService.setByScript(script);
                observer.next(script);
                observer.complete();
            }, error => {
                console.error(error);
                this.router.navigate(['design']);
            });
        });
    }
}
