import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentReference, refEqual, queryEqual, Query } from "@angular/fire/firestore";

@Injectable(
    { providedIn: 'root' }
)
export class FirebaseCache {
    public models: Map<DocumentReference, Observable<any>> = new Map();
    public queries: Map<Query, Observable<Array<any>>> = new Map();

    public getModel(docRef: DocumentReference) {
        let found = null;
        this.models.forEach((model, ref) => {
            if (refEqual(ref, docRef)) {
                found = model;
            }
        });
        return found;
    }

    public setModel(docRef: DocumentReference, model: Observable<any>) {
        this.models.set(docRef, model);
    }

    public getQuery(query: Query) {
        let found = null;
        this.queries.forEach((models, _query) => {
            if (queryEqual(query, _query)) {
                found = models;
            }
        });
        return found;
    }
    
    public setQuery(query: Query, models: Observable<Array<any>>) {
        this.queries.set(query, models);
    }
}
