// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  app: 'player',
  environment: 'staging',
  title: 'Player - Staging',
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBsUgff6QA797OcI3ASOfim3Q_0Hu9UFQ4",
    authDomain: "gamoteca-161414.firebaseapp.com",
    databaseURL: "https://gamoteca-161414.firebaseio.com",
    projectId: "gamoteca-161414",
    storageBucket: "gamoteca-161414.appspot.com",
    messagingSenderId: "173579923923",
    appId: "1:173579923923:web:af4e3e9234da00de4cff26",
    measurementId: "G-9XCV2W23K9"
  },
  homeURL: '/playing/browse/play-list',
  web: {
    url: 'https://staging.play.gamoteca.com',
    playUrl: 'https://staging.play.gamoteca.com'
  },
  iOS: {
    bundleId: 'com.gamoteca.play',
    playBundleId: 'com.gamoteca.play',
    appId: '1238297749',
    playAppId: '1238297749'
  },
  android: {
    packageName: 'com.gamoteca.app',
    playPackageName: 'com.gamoteca.app'
  },
  auth: {
    google: {
      webClientID: '173579923923-iu79s1afh5hkibhmcip621esd4g0km9t.apps.googleusercontent.com'
    }
  },
  sentry: {
    project: 'gamoteca-player-dev',
    dsn: 'https://c5e0256e236c46c499aecc21bfae2ff9@o783198.ingest.sentry.io/5798727',
    tracingOrigins: ["localhost", "https://staging.play.gamoteca.com"],
  },
  gamotecaOrgID: 'kKWXNbsGpgUN7R2UoAE0',
  dynamicLinkDomain: 'link.gamoteca.com',
  dynamicLinkDomainPlayer: 'link.gamoteca.com',
  analytics: {
    googleAnalytics: {
      trackID: 'UA-122932046-1',
    },
    mixpanel: {
      trackID: '9f5ef1e2b9fce2ec64f13234846160d6'
    },
    intercom: {
      trackID: 'bsgdczbx'
    }
  },
  moodle: {
    validWorkspaces: [
      'global-games'
    ],
  },
  payment: {
    stripe: {
      clientKey: 'pk_live_51H0qkYCnWdWlNRlbN1kP4E15jDGftWi2v1vj2GGD56j9lO8Pul9J5TxC2yGHOAaURVf3ROh0H8r2qioX8NxxKhCB0041NGVdeJ'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
