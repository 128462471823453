import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'src/app/core/analytics/analytics';

@Injectable()
export class AppUpdateAnalyticsService {
  constructor(
    private analyticsService: AnalyticsProvider,
    private translateService: TranslateService
  ) {
    this.analyticsService.setCurrentScreen('App update popup');
  }

  onUpdateButtonClicked(): void {
    this.analyticsService.event(
      ['update button clicked'],
      'Ok'
    );
  }

}
