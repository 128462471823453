import { LayoutItem } from './task-layout-item';
import { Task } from './task.model';
import { ref, updateMetadata, uploadBytes } from '@angular/fire/storage';

export class Image360LayoutItem extends LayoutItem {
    public type: string = LayoutItem.TYPE_IMAGE_360;
    public order = 30;

    public properties: {
        sizes?: {
            original: {
                ref?: string;
                url: string;
            }
        },
    };

    public setFields(data: any) {
        this.properties = {
            sizes: {
                original: {
                    ref: null,
                    url: null
                }
            }
        };
        super.setFields(data);
    }

    public get url() {
        if (this.properties && this.properties.sizes && this.properties.sizes.original && this.properties.sizes.original.url) {
            return this.properties.sizes.original.url;
        }
        return undefined;
    }

    public setImage(url: string, ref: string = null) {
        this.properties.sizes = {
            original: {
                url: url,
                ref: ref,
            }
        };
    }

    public async uploadFile(category: string, file: File, metadata?: any) {
        const path = (this.documentInstance as Task).getDocument().path + '/' + this.type + '/' + file.name;
        const reference = ref(this.modelProvider.fsStorage, path);
        await uploadBytes(reference, file);
        updateMetadata(reference, { customMetadata: metadata || {} });
        return { ref: reference, uploadTask: reference};
    }
}
