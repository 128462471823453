import { FirestoreModel } from '../firebase/firestore-model';
import { Observable, of } from 'rxjs';
import { DocumentReference, collectionGroup, query, where, QueryConstraint, Timestamp, collection } from "@angular/fire/firestore";
import { User } from './user.model';
import { Organization } from './organization.model';
import { filter, switchMap } from 'rxjs/operators';
import { httpsCallable } from "@angular/fire/functions";

export type OrganizationMemberLevel = 'owner' | 'admin' | 'billing' | 'player';

export class OrganizationMember extends FirestoreModel<OrganizationMember> {
  public COLLECTION_NAME = 'members';
  active: boolean;
  level: OrganizationMemberLevel;
  userRef: DocumentReference;
  joinedAt: Timestamp;
  isInvitationAccepted: boolean;

  protected rules() {
    return {
      active: {},
      level: {},
      userRef: {},
      joinedAt: {},
      isInvitationAccepted:{}
    };
  }

  protected instantiate(path, data, options?: any) {
    return new OrganizationMember(path, data, options, this.modelProvider);
  }

  get organizationRef() {
    return this.ref.parent.parent;
  }

  /**
   * User
   */
  private _user$: Observable<User>;
  public get user$(): Observable<User> {
    if (this._user$ !== undefined) { return this._user$; }

    this._user$ = this.modelProvider.user.findByRef(this.userRef);
    return this._user$;
  }

  public findAllByOrganization(organization: Organization, query?: QueryConstraint[]): Observable<Array<OrganizationMember>> {
    const membersCollectionRef = collection(organization.ref, 'members');
    return this.findAllBy(query, membersCollectionRef);
  }
  
  public findAllByUser$(user: User, queryConstraints: QueryConstraint[] = []): Observable<Array<OrganizationMember>> {
    const membersCollection = collectionGroup(this.modelProvider.fsDB, 'members'); 
    const constraints = [where('userRef', '==', user.ref), ...queryConstraints];
    const queryRef = query(membersCollection, ...constraints);

    return this.findAllBy(null, null, queryRef);
  }

  public findAllByUserRef$(userRef: DocumentReference, queryConstraints: QueryConstraint[] = []): Observable<Array<OrganizationMember>> {
    const baseQueryConstraints: QueryConstraint[] = [
        where('userRef', '==', userRef),
        ...queryConstraints 
    ];

    return this.findAllBy(baseQueryConstraints);
  }

  private _findAllMy$: Observable<Array<OrganizationMember>>
  public get findAllMy$() {
    if(this._findAllMy$){ return this._findAllMy$; }
    this._findAllMy$ = this.modelProvider.user.getMe$().pipe(filter(user => !!user), switchMap(user => {
      if(user) {
        return this.modelProvider.organizationMember.findAllByUser$(user);
      } else {
        return of([]);
      }
    }))
    return this._findAllMy$;
  }

  async changeLevel(level: OrganizationMemberLevel) {
    const result = await httpsCallable(this.modelProvider.functions, 'organizationChangeLevel')({
      memberPath: this.ref.path,
      level: level,
    });
    return result.data;
  }

  public async deactivate(isDeleteFromWorkspaces: boolean) {
    const result = await httpsCallable(this.modelProvider.functions, 'organizationMemberDeactivate')({
      memberPath: this.ref.path,
      isDeleteFromWorkspaces: isDeleteFromWorkspaces
    });
    return result.data; 
  }

  public async reactivate() {
    const result = await httpsCallable(this.modelProvider.functions, 'organizationMemberReactivate')({
      memberPath: this.ref.path,
    });
    return result.data; 
  }

}
