import { FirestoreModel } from '../firebase/firestore-model';
import { Task } from './task.model';
import { Observable, EMPTY, firstValueFrom } from 'rxjs';
import { Role } from './role.model';
import { Play } from './play.model';
import { FirebaseFileUploaderEntity } from '../firebase/firebase-file-uploader-entity';
import { map, shareReplay } from 'rxjs/operators';
import { Script } from './script.model';
import { LocalizedText } from './general/localized-text';
import { Model } from './general/model';
import { ImageWithSizes } from './general/image-with-sizes';
import { Timestamp, collection, doc, DocumentReference } from "@angular/fire/firestore";
import { ref, updateMetadata, uploadBytesResumable } from "@angular/fire/storage";

export class ScriptVersion extends FirestoreModel<ScriptVersion> implements FirebaseFileUploaderEntity {
  public COLLECTION_NAME = 'versions';

  public _title: LocalizedText;
  public get title(): string {
    return this._title.getText();
  }
  public set title(text: string) {
    this._title.setText(text);
  }

  _image: ImageWithSizes;
  public get imageUrl() {
    return this._image ? this._image.getSizedUrl('sm') : null;
  }
  public set imageUrl(url: string) {
    this._image.setSizedUrl(url);
  }

  public _description: LocalizedText;
  public get description(): string {
    return this._description.getText();
  }
  public set description(text: string) {
    this._description.setText(text);
  }
  languages: {
    [languageID: string]: string;
  };
  lastModified: Timestamp;
  teaser: {
    url?: string;
  };
  status: string;
  active: boolean;
  direction: 'ltr' | 'rtl';
  gameMode: 'multiplayer' | 'single-player';
  hasFacilitator: boolean;

  protected rules() {
    return {
      _title: { [Model.RULE_ALIAS]: 'title', [Model.RULE_CLASS]: LocalizedText },
      _image: {
        [Model.RULE_ALIAS]: 'image',
        [Model.RULE_CLASS]: ImageWithSizes,
        [Model.RULE_DEFAULT]: () => new ImageWithSizes({}, this, {}, this.modelProvider)
      },
      _description: { [Model.RULE_ALIAS]: 'description', [Model.RULE_CLASS]: LocalizedText },
      languages: {},
      lastModified: {},
      teaser: {},
      status: {},
      active: {},
      direction: {},
      gameMode: {},
      hasFacilitator: {}
    };
  }

  /**
   * Task
   */
  // tslint:disable-next-line: member-ordering
  private _tasks$: Observable<Task[]>;
  public get tasks$(): Observable<Task[]> {
    if (this._tasks$ !== undefined) { return this._tasks$; }

    this._tasks$ = this.modelProvider.task.findAllByScriptVersion(this).pipe(shareReplay(1));
    return this._tasks$;
  }

  // tslint:disable-next-line: member-ordering
  private _tasks: Array<Task>;
  public get tasks(): Array<Task> {
    if (this._tasks !== undefined) { return this._tasks; }
    this._tasks = [];

    this.tasks$.subscribe(tasks => {
      this._tasks = tasks;
    });
    return this._tasks;
  }

  public get rolesPlayer$() {
    return this.roles$.pipe(map(roles => roles.filter(role => role.type === 'player')))
  }

  /**
   * Roles
   */
  // tslint:disable-next-line: member-ordering
  private _roles$: Observable<Role[]>;
  public get roles$(): Observable<Role[]> {
    if (this._roles$) { return this._roles$; }

    this._roles$ = this.modelProvider.role.findAllByVersion(this);

    return this._roles$;
  }

  // tslint:disable-next-line: member-ordering
  private _roles: Array<Role>;
  public get roles(): Array<Role> {
    if (this._roles !== undefined) { return this._roles; }
    this._roles = [];

    this.roles$.subscribe(roles => {
      this._roles = roles;
    });
    return this._roles;
  }

  public get rolesAsString(): string {
    return this.roles.sort((a: Role, b: Role) => {
      if (a.type === 'facilitator') {
        return 1;
      }
      if (b.type === 'facilitator') {
        return -1;
      }

      if (a.order && b.order && a.order !== b.order) {
        return (a.order > b.order) ? -1 : 1;
      } else {
        return ('' + a.title).localeCompare(b.title);
      }
    }).map(role => {
      if (role.type === 'facilitator') {
        return role.title;
      }

      return role.title;


    }).join(
      this.direction === 'rtl' ? ' ,' : ', '
    );
  }

  /**
   * Plays
   */
  // tslint:disable-next-line: member-ordering
  private _plays$: Observable<Play[]>;
  public get plays$(): Observable<Play[]> {
    if (this._plays$) { return this._plays$; }

    this._plays$ = this.modelProvider.play.findAllByScriptVersion(this);
    return this._plays$;
  }

  private _plays: Array<Play>;
  public get events(): Array<Play> {
    if (this._plays !== undefined) { return this._plays; }
    this._plays = [];

    this.plays$.subscribe(plays => {
      this._plays = plays;
    });
    return this._plays;
  }

  public findByID(id: string, scriptID?: string) {
    if (scriptID) {
      return this.findByRef('scripts/' + scriptID + '/versions/' + id);
    } else {
      return super.findByID(id);
    }
  }
  public findAllByScript(script: Script): Observable<ScriptVersion[]> {
    const versionsCollection = collection(this.modelProvider.fsDB, `${script.getDocument().path}/versions`);
    return this.findAllBy(null, versionsCollection);
  }
  
  public getDraftByScript(script: Script): Observable<ScriptVersion> {
    const draftRef: DocumentReference = doc(this.modelProvider.fsDB, `${script.getDocument().path}/versions/draft`);
    return this.findByRef(draftRef, { updateSelfFields: false });
  }

  public getPublicByScript(script: Script): Observable<ScriptVersion> {
    if (!script.public || !script.public.versionRef) {
      // tslint:disable-next-line: deprecation
      return EMPTY;
    }
    return this.findByRef(script.public.versionRef.path);
  }

  public instantiate(path, data, options?: any) {
    return new ScriptVersion(path, data, options, this.modelProvider);
  }

  public getStorageReferencePath(category: string, file: File) {
    return this.getDocument().path + '/' + category + '/' + file.name;
  }

  public uploadFile(category: string, file: File, metadata?: any, extras?: any) {
    const reference = ref(this.modelProvider.fsStorage, this.getStorageReferencePath(category, file));
    updateMetadata(reference, { customMetadata: metadata || {} });
    const uploadTask = uploadBytesResumable(reference, file);
    return {
      ref: reference,
      uploadTask
    };
  }

  public get scriptRef() {
    return this.ref.parent.parent;
  }

  async save() {
    this.lastModified = Timestamp.now();
    this.active = true;
    return await super.save();
  }

  hasTeaser(): boolean {
    return this.teaser && this.teaser.url !== undefined && this.teaser.url !== null && this.teaser.url !== '';
  }

  // Legacy
  async _gameMode() {
    if (this.gameMode) { return this.gameMode }
    return await firstValueFrom(this.roles$.pipe(
      map(roles => {
        const filteredRoles = roles.filter(role => role.type === 'player');
        return filteredRoles.length > 1 ? 'multiplayer' : 'single-player'
      })));
  }

  // Legacy
  async _hasFacilitator() {
    if (this.hasFacilitator) { return this.hasFacilitator }
    
    return await firstValueFrom(this.roles$.pipe(
      map(roles => {
        const filteredRoles = roles.filter(role => role.type === 'facilitator');
        return filteredRoles.length >= 1;
      })));
  }
}
