import "@angular/compiler";
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { provideIonicAngular, AnimationController,Platform, IonicRouteStrategy } from '@ionic/angular/standalone';
import { provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';

// Firebase Imports
import { LogLevel, setLogLevel } from '@angular/fire';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getRemoteConfig, provideRemoteConfig, RemoteConfig } from '@angular/fire/remote-config';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getAnalytics, Analytics, provideAnalytics } from "@angular/fire/analytics";
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from "@angular/fire/storage";

// Third-party Modules
import { TranslateModule, TranslateLoader, TranslateService  } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { provideNgxStripe } from 'ngx-stripe';
import { provideQuillConfig } from 'ngx-quill/config';
import { provideEnvironmentNgxMask } from "ngx-mask";

// Ionic Native Plugins
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Device } from '@ionic-native/device/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { FirebaseDynamicLinks } from "@ionic-native/firebase-dynamic-links/ngx";
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// Analytics & Tracking
import { AnalyticsProvider } from './app/core/analytics/analytics';
import { Angulartics2, ANGULARTICS2_TOKEN, RouterlessTracking, AngularRouterTracking, Angulartics2Module, Angulartics2GoogleTagManager, Angulartics2GoogleAnalytics, Angulartics2Mixpanel, Angulartics2RouterlessModule } from "angulartics2";

// Routes
import { appRoutes } from "./app/app.route";

setLogLevel(LogLevel.SILENT);

export function initializeAnalytics(): void {
    const GA_LOCAL_STORAGE_KEY = 'GA_LOCAL_STORAGE_KEY';
    (<any>window).mixpanel.init(environment.analytics.mixpanel.trackID);
    (<any>window).ga('create', environment.analytics.googleAnalytics.trackID, 'auto', {
        'storage': 'none',
        'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY),
        allowLinker: true
    });
    (<any>window).ga('require', 'linker');
    (<any>window).ga('linker:autoLink', ['gamoteca.com', 'www.gamoteca.com', 'play.gamoteca.com', 'app.gamoteca.com']);
    (<any>window).ga((tracker) => {
        localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
    });
    (window as any).ga('set', 'checkProtocolTask', null);
    if ((environment?.analytics as any)?.googleAds?.trackID) {
        (window as any).gtag('config', (environment?.analytics as any)?.googleAds?.trackID);
    }
    (<any>window).Intercom('boot', { app_id: environment.analytics.intercom.trackID });
}

initializeAnalytics();

if (environment.production) {
    enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        { provide: ANGULARTICS2_TOKEN, useValue: {  } },
        { provide: RouterlessTracking, useClass: AngularRouterTracking },
        importProvidersFrom(
            NgxPopperjsModule.forRoot({}),
            BrowserModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }),
            Angulartics2Module.forRoot(),
            Angulartics2RouterlessModule.forRoot({
                pageTracking: {
                    excludedRoutes: [
                        new RegExp('/.*/')
                    ],
                }
            }),
            IonicStorageModule.forRoot(),  
        ),
        Angulartics2,
        TranslateService,
        AppVersion,
        Device,
        Facebook,
        GooglePlus,
        SignInWithApple,
        InAppBrowser,
        StatusBar,
        SplashScreen,
        FirebaseMessaging,
        Platform,
        FirebaseAnalytics,
        Angulartics2GoogleAnalytics,
        Angulartics2GoogleTagManager,
        Angulartics2Mixpanel,
        AnalyticsProvider,
        FirebaseDynamicLinks,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        provideFirestore(() => getFirestore()),
        provideFunctions(() => getFunctions()),
        provideMessaging(() => getMessaging()),
        provideRemoteConfig(() => {
            const remoteConfig: RemoteConfig = getRemoteConfig();
            remoteConfig.defaultConfig = {
                minimumFetchIntervalMillis: 10000,
            };
            return remoteConfig;
        }),
        providePerformance(() => getPerformance()),
        provideAnalytics(() => {
            const analytics: Analytics = getAnalytics();
            analytics.app.automaticDataCollectionEnabled = true;
            return analytics;
        }),
        provideAuth(() => getAuth()), 
        provideStorage(()=> getStorage()),
        provideNgxStripe(environment.payment.stripe.clientKey),
        provideQuillConfig({
            suppressGlobalRegisterWarning: true,
        }),
        provideIonicAngular({
            navAnimation: () => new AnimationController().create(),
            innerHTMLTemplatesEnabled: true
        }),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(appRoutes),
        provideEnvironmentNgxMask(),
    ]
}).catch(err => console.log(err));