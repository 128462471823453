import { FirestoreModel } from '../firebase/firestore-model';
import { map, first, shareReplay } from 'rxjs/operators';
import { DocumentReference, QueryConstraint, Timestamp, refEqual, where, collection } from '@angular/fire/firestore';
import { Team } from './team.model';
import { ScriptVersion } from './script-version.model';
import { Player } from './player.model';
import { Observable } from 'rxjs';
import { Role } from './role.model';
import { Workspace } from './workspace.model';
import { Script } from './script.model';
import { environment } from 'src/environments/environment';

export class Play extends FirestoreModel<Play> {
    static STATUS_PRESTART = 'pre-start';
    static STATUS_ONGOING = 'on-going';
    static STATUS_FINISHED = 'finished';
    static TYPE_ORGANIZED = 'organized';
    static TYPE_INSTANT = 'public-game';
    public COLLECTION_NAME = 'plays';

    public title: string;
    public accessLevel: string;
    public description: string;
    public location: string;
    public scriptRef: DocumentReference;
    public scriptVersionRef: DocumentReference;
    public share: {
        code: string
    };
    public feed?: {
        postEnabled: boolean
    };
    public workspaceRef: DocumentReference;
    public announcedStartTime: Timestamp;
    public organizerName: string;
    public organizerEmail: string;
    public createdAt: Timestamp;
    public createdBy: DocumentReference;
    public status: string;
    public type: string;
    public slots: {
        available: number,
        enrolled: number,
        total: number
        roles: {
            [roleID: string]: {
                available: number,
                enrolled: number,
                total: number
            }
        }
    };
    public gameMode: 'multiplayer' | 'single-player';
    public hasFacilitator: boolean;
    public startOn: 'players-ready' | 'manually';
    public playerCanSelectRole: boolean;
    public startedAt: Timestamp;
    public finishedAt: Timestamp;
    public organizationRef: DocumentReference;
    public isArchived: boolean;

    public rules() {
        return {
            title: {},
            accessLevel: {},
            description: {},
            location: {},
            scriptRef: {},
            scriptVersionRef: {},
            share: {},
            workspaceRef: {},
            announcedStartTime: {},
            organizerName: {},
            organizerEmail: {},
            createdAt: {},
            createdBy: {},
            status: {},
            type: {},
            feed: {},
            slots: {},
            startOn: {},
            startedAt: {},
            finishedAt: {},
            playerCanSelectRole: {},
            gameMode: {},
            hasFacilitator: {},
            organizationRef: {},
            isArchived: {}
        };
    }

    public get organizeType() {
        if (this.type === 'organized-game') {
            return 'organized-game';
        } else if (this.type === 'public-game') {
            if (this.accessLevel === 'private') {
                return 'instant-game';
            } else {
                return 'invite-game';
            }
        }
    }

    public get organizeTypeForAnalytics() {
        if (this.type === 'organized-game') {
            return 'Custom';
        } else if (this.type === 'public-game') {
            if (this.accessLevel === 'private') {
                return 'Random';
            } else {
                return 'Invite';
            }
        }
    }


    /**
     * Script Version
     */
    // tslint:disable-next-line: member-ordering
    private _scriptVersion$: Observable<ScriptVersion>;
    private _scriptVersion$Ref: DocumentReference;
    public get scriptVersion$(): Observable<ScriptVersion> {
        if (this._scriptVersion$ !== undefined && refEqual(this._scriptVersion$Ref,this.scriptVersionRef)) { return this._scriptVersion$; }

        this._scriptVersion$Ref = this.scriptVersionRef;
        this._scriptVersion$ = this.modelProvider.scriptVersion.findByRef(this.scriptVersionRef);
        return this._scriptVersion$;
    }
    // tslint:disable-next-line: member-ordering
    private _scriptVersion: ScriptVersion;
    public get scriptVersion(): ScriptVersion {
        if (this._scriptVersion !== undefined) { return this._scriptVersion; }
        this._scriptVersion = null;

        this.scriptVersion$.subscribe(scriptVersion => {
            this._scriptVersion = scriptVersion;
        });
        return this._scriptVersion;
    }

    /**
     * Script
     */
    // tslint:disable-next-line: member-ordering
    private _script$: Observable<Script>;
    public get script$(): Observable<Script> {
        if (this._script$ !== undefined) { return this._script$; }

        this._script$ = this.modelProvider.script.findByRef(this.scriptRef);
        return this._script$;
    }
    // tslint:disable-next-line: member-ordering
    private _script: Script;
    public get script(): Script {
        if (this._script !== undefined) { return this._script; }
        this._script = null;

        this.script$.subscribe(script => {
            this._script = script;
        });
        return this._script;
    }

    /**
     * Workspace
     */
    // tslint:disable-next-line: member-ordering
    private _workspace$: Observable<Workspace>;
    public get workspace$(): Observable<Workspace> {
        if (this._workspace$ !== undefined) { return this._workspace$; }       
        this._workspace$ = this.modelProvider.workspace.findByRef(this.workspaceRef);
        return this._workspace$;
    }

    /**
     * Teams
     */
    // tslint:disable-next-line: member-ordering
    private _team$: Observable<Team[]>;
    public get teams$(): Observable<Team[]> {
        if (this._team$ !== undefined) { return this._team$; }

        this._team$ = this.modelProvider.team.findAllByPlay(this).pipe(shareReplay(1));
        return this._team$;
    }
    // tslint:disable-next-line: member-ordering
    private _teams: Array<Team>;
    public get teams(): Array<Team> {
        if (this._teams !== undefined) { return this._teams; }
        this._teams = [];
        this.teams$.pipe(first()).subscribe(teams => {
            this._teams = teams;
        });

        return this._teams;
    }
    /**
     * Members
     */
    // tslint:disable-next-line: member-ordering
    private _players$: Observable<Player[]>;
    public get players$(): Observable<Player[]> {
        if (this._players$ !== undefined) { return this._players$; }

        this._players$ = this.modelProvider.player.findAllByPlay(this);
        return this._players$;
    }
    // tslint:disable-next-line: member-ordering
    private _players: Array<Player>;
    public get players(): Array<Player> {
        if (this._players !== undefined) { return this._players; }
        this._players = [];

        this.players$.pipe(first()).subscribe(players => {
            this._players = players;
        });

        return this._players;
    }

    public get shareURL() {
        const url = 'https://' + environment.dynamicLinkDomainPlayer + '/?link=' +
            environment.web.playUrl + '/playing/event/' + this.id +
            '&apn=' + environment.android.playPackageName + '&isi=' + environment.iOS.playAppId + '&ibi=' + environment.iOS.playBundleId;

        return url;
    }

    public findAllByWorkspace(workspace: Workspace, query: QueryConstraint[] = []) {
        return this.findAllBy([where('workspaceRef', '==', workspace.getDocument()), ...query]);
    }

    public findAllByScriptVersion(script: ScriptVersion) {
        return this.findAllBy([where('scriptRef', '==', script.getDocument())]);
    }

    findBySharingCode$(code: string): Observable<Play> {
        return this
            .findAllBy([where('share.code', '==', code)], collection(this.modelProvider.fsDB, 'plays'))
            .pipe(map(plays => plays[0]));
    }

    public findPlayerForRole(role: Role) {
        return this.modelProvider.player.findAllByPlayAndRole(this, role).pipe(first());
    }

    protected instantiate(path, data, options?: any) {
        return new Play(path, data, options, this.modelProvider);
    }
}
