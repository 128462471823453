import { LayoutItem } from './task-layout-item';
import { isEmpty } from 'lodash-es';

export class InputTextLayoutItem extends LayoutItem {
    public type = LayoutItem.TYPE_INPUT_TEXT;
    public order = 50;
    public properties: {
        question: {
            languages?: {
                [languageID: string]: string;
            },
        }
    };

    public get question() {
        return this.modelProvider.localize.getText(this.properties.question);
    }

    public set question(text: string) {
        this.properties.question = this.modelProvider.localize.setText(this.properties.question, text);
    }

    public hasQuestion() {
        return !isEmpty(this.question);
    }
}

