import { Injectable } from '@angular/core';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Platform } from '@ionic/angular/standalone';
import { ModelProvider } from '../models/general/model.provider';
import { Device } from '@ionic-native/device/ngx';
import { isSupported as isFireMessagingSupported, getToken, deleteToken as deleteFireMessagingToken} from "@angular/fire/messaging";

@Injectable({
  providedIn: 'root'
})
export class CloudMessagingService {

  constructor(
    private modelProvider: ModelProvider,
    private firebaseMessaging: FirebaseMessaging,
    private platform: Platform,
    private device: Device,
  ) { }

  async requestPermission() {
    if (this.platform.is('cordova')) {
      return this.firebaseMessaging.requestPermission({ forceShow: true }).then(token => {
        return this.firebaseMessaging.getToken();
      })
    } else {
      return await getToken(this.modelProvider.fsMessaging);
    }
  }

  async storeToUser(token: string, _deviceID?: string) {
    const deviceID = _deviceID || this.getDeviceID();
    const me = await this.modelProvider.user.getMe();
    me.device[deviceID]['pushNotificationToken'] = token;
    await me.save();
  }

  async hasToken(_deviceID?: string) {
    const me = await this.modelProvider.user.getMe();
    const deviceID = _deviceID || this.getDeviceID();
    return !!(me?.device[deviceID]?.pushNotificationToken);
  }

  async deleteToken(token: string) {
    return await deleteFireMessagingToken(this.modelProvider.fsMessaging);
  }

  public getDeviceID() {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      return 'web';
    }
  }

  public isSupported() {
    if(this.platform.is('cordova')) {
      return true;
    } else {
      return isFireMessagingSupported();
    }
  }
}
