import { Injectable } from '@angular/core';
import { AnalyticsProvider } from './core/analytics/analytics';
import { AuthService } from './auth/auth.service';
import { Platform } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppAnalyticsService {

  private version: string;

  constructor(
    protected analytics: AnalyticsProvider,
    private auth: AuthService,
    private platform: Platform
  ) {
    this.analytics.startTracking();

    (async () => {
      this.version = await this.auth.getAppVersion();

      this.auth.authState().subscribe(async user => {
        this.onAppOpened();
      });

    })();
  }

  onAppOpened() {
    this.analytics.event('loaded', environment.app, {
      app: environment.app,
      environment: environment.environment,
      version: this.version,
      platforms: this.platform.platforms().join(', ')
    }, 'App');
  }

  onAppClosed() {
    this.analytics.event('closed', environment.app, {
      app: environment.app,
      environment: environment.environment,
      version: this.version,
      platforms: this.platform.platforms().join(', ')
    }, 'App');
  }

}
