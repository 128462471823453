import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '../models/user.model';
import { Workspace } from '../models/workspace.model';
import { Storage } from '@ionic/storage';
import { ModelProvider } from '../models/general/model.provider';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subscription, of, Observable, firstValueFrom } from 'rxjs';
import { take, first, map, switchMap } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';
import { OrganizationService } from 'src/app/organization/organization.service';
import { Organization } from '../models/organization.model';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {

  public static DEFAULT_WORKSPACE_KEY = 'defaultWorkspacePath';

  initCall = true;
  user: User;
  activeWorkspace: Workspace;
  activatedWorkspace = new ReplaySubject<Workspace>(1);
  workspaces: Workspace[] = [];
  workspaces$ = new ReplaySubject<Workspace[]>(1);

  constructor(private auth: AuthService,
    private storage: Storage,
    public modelProvider: ModelProvider,
    private translateService: TranslateService,
    private organizationService: OrganizationService
  ) {
    this.activatedWorkspace.subscribe(async workspace => {
      if (workspace) {
        const member = await workspace.getMyMember();
        if (member.level!=='player' && member.id !== 'guest') {
          await this.organizationService.setByWorkspace(workspace);
          await this.storage.set(WorkspaceService.DEFAULT_WORKSPACE_KEY, workspace.getReference().path);
        }
      }
    });

    let userWorkspacesSubscription: Subscription = null;

    this.auth.user$.subscribe(user => {
      if ((user && user.ref.path) === (this.user && this.user.ref.path)) { return; }
      this.user = user;
      if (user) {
        if (userWorkspacesSubscription) {
          userWorkspacesSubscription.unsubscribe();
        }
        userWorkspacesSubscription = this.user.workspaces$.subscribe(workspaces => {
          this.workspaces$.next(workspaces);
          this.workspaces = workspaces;
        });
        this.user.workspaces$.pipe(first()).subscribe(async workspaces => {
          await this.setActiveWorkspace();
        });
      }
    });
  }

  private _hasDesignerWorkspace$: Observable<boolean>;
  public hasDesignerWorkspace$() {
    if (this._hasDesignerWorkspace$) { return this._hasDesignerWorkspace$ };

    this._hasDesignerWorkspace$ = this.auth.user$.pipe(
      switchMap(
        user => user
          ? this.modelProvider.workspaceMember
            .findAllByUser$(this.modelProvider.user.meReference)
            .pipe(
              map(workspaceMembers => workspaceMembers.filter(member => member.level !== 'player'))
            )
          : of([])
      ),
      map(nonPlayerMembers => nonPlayerMembers.length > 0)
    )
    return this._hasDesignerWorkspace$;
  }

  public async setActiveWorkspaceByRef(workspaceRef: DocumentReference) {
    const workspace = await firstValueFrom(this.modelProvider.workspace.findByRef(workspaceRef));
    this.setActiveWorkspace(workspace);
  }

  public async refresh() {
    const workspace = await firstValueFrom(this.activatedWorkspace);
    await this.setActiveWorkspaceByRef(workspace.ref);
  }

  public async setActiveWorkspace(workspace?: Workspace) {
    if (!workspace && !this.activeWorkspace) {
      workspace = await this.getDefaultWorkspace();
    }

    if (workspace) {
      this.activeWorkspace = workspace;
      this.activatedWorkspace.next(workspace);
    }
  }

  public async getDefaultWorkspace() {
    const storedWorkspacePath: string = await this.storage.get(WorkspaceService.DEFAULT_WORKSPACE_KEY);
    const lastWorkspace = await this.workspaces.find(async (workspace) => {
      const member = await workspace.getMyMember();
      return workspace.ref.path === storedWorkspacePath && member.level!='player';
    })
    if (lastWorkspace) {
      return lastWorkspace;
    }

    return this.getWorkspace();
  }

  public hasOnlyPersonalWorkspace() {
    return false;
  }

  public async getWorkspace() {
    const workspaces = await this.workspaces.filter(async (workspace)=>{
      const member = await workspace.getMyMember();
      return member.level !== 'player';
    });
    return workspaces[0];
  }

  public getPersonalWorkspace() {
    const personalWorkspace = this.workspaces.filter(workspace => workspace.isPersonal && workspace.myMember.level!='player')[0];
    if (personalWorkspace) {
      return personalWorkspace;
    }

    if (this.workspaces.length > 0) {
      this.workspaces[0];
    }
  }

  public getFirstNonPersonalWorkspace() {
    const workspaces = this.getNonPersonalWorkspaces();
    return workspaces.length > 0 ? workspaces[0] : null;
  }

  public getNonPersonalWorkspaces() {
    return this.workspaces.filter(workspace => !workspace.isPersonal);
  }

  public getWorkspaceWithId(id: string) {
    const filtered = this.workspaces.filter(workspace => workspace.getReference().id === id);
    return filtered.length > 0 ? filtered[0] : null;
  }

  public isPersonalWorkspace(workspace: Workspace): boolean {
    return workspace.getReference().id === this.getPersonalWorkspace().getReference().id;
  }

  public async fallbackToFirstNonPersonalWorkspace() {
    if (!this.hasOnlyPersonalWorkspace()) {
      const active = await firstValueFrom(this.activatedWorkspace.pipe(take(1)));
      if (this.isPersonalWorkspace(active)) {
        this.setActiveWorkspace(this.getFirstNonPersonalWorkspace());
      }
    }
  }

  async addNew(organization: Organization) {
    const isValidWorkspaceLimit = await organization.isValidWorkspaceLimit();
    if (!isValidWorkspaceLimit) {
      await this.organizationService.showWorkspaceLimitWarning();
      throw new Error('workspace-limit-reached');
    }

    const response: any = await this.modelProvider.callFunction('workspaceCreate', {
      organizationPath: organization.ref.path,
      workspaceTitle: this.translateService.instant('workspace/title/new')
    });

    if (response && response.workspacePath) {
      const workspace = await firstValueFrom(this.modelProvider.workspace.findByRef(response.workspacePath));
      return workspace;
    }

    return false;
  }
}
