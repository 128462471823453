import { Injectable } from '@angular/core';
import { AnalyticsProvider } from '../core/analytics/analytics';
import { AnalyticsService } from '../core/analytics/analytics.service';

@Injectable()
export class WelcomeAnalyticsService implements AnalyticsService {
  constructor(public analyticsService: AnalyticsProvider) {
    this.analyticsService.setCurrentScreen('Welcome');
  }

  createRegisterClickedEvent(): void {
    this.analyticsService.event('Register clicked');
  }

  createSignInClickedEvent(): void {
    this.analyticsService.event('Sign in clicked');
  }

  createSignInWithFacebookClickedEvent(): void {
    this.analyticsService.event('Sign in with Facebook clicked', null, {type: 'button'});
  }

  createSignInWithGoogleClickedEvent(): void {
    this.analyticsService.event('Sign in with Google clicked', null, {type: 'button'});
  }

  createSignInWithAppleClickedEvent(): void {
    this.analyticsService.event('Sign in with Apple clicked', null, {type: 'button'});
  }

  partnerOpened(providerID: string, label: string): void {
    this.analyticsService.event(['Partner', 'page opened'], label, {
      'provider-id': providerID
    }, 'Sign In');
  }  

  loginWithPartner(providerID: string, label: string): void {
    this.analyticsService.event(['Partner', 'login button clicked'], label, {
      'provider-id': providerID
    }, 'Sign In');
  }

}
