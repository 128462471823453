import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocalizeService {
    public get language() {
        return 'en-us';
    }


    public setText(field: { languages?: { [languageID: string]: string } }, value: string) {
        if (!field) {
            field = {};
        }
        if (!field.languages) {
            field.languages = {};
        }
        field.languages[this.language] = value;
        return field;
    }

    public getText(field: object) {
        if (field && field['languages'] && field['languages'][this.language]) {
            return field['languages'][this.language];
        }
        return '';
    }

    public asData(value: string) {
        return this.setText({}, value);
    }
}
