import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { first, map } from "rxjs/operators";
import { NavController } from "@ionic/angular/standalone";
import { ModelProvider } from "../core/models/general/model.provider";
import { environment } from "src/environments/environment";
import { applyActionCode } from '@angular/fire/auth';

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  public redirectUrl: string = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private navCtrl: NavController,
    private modelProvider: ModelProvider
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.authService
        .authState()
        .pipe(first())
        .subscribe(async (user) => {
          if (state.root.queryParamMap.has("verificationCode")) {
            try {
              await applyActionCode(this.modelProvider.fsAuth, state.root.queryParamMap.get("verificationCode"));
            } catch (error) {
              console.error(error);
            }
          }
          const whiteListedRoutes = [
            "playing/browse/design-details/:scriptID",
            "organization/create/free",
          ];

          if (
            state.root.queryParamMap.has("apiKey") &&
            state.root.queryParamMap.get("mode") === "signIn" &&
            state.root.queryParamMap.has("email")
          ) {
            try {
              await this.authService.singInWithEmailLink(
                state.root.queryParamMap.get("email"),
                window.location.href
              );

              if (whiteListedRoutes.includes(next.routeConfig.path)) {
                resolve(true);
                return;
              }

              if (environment.app === "player") {
                const hasOrganization = await this.hasOrganization();
                if (!hasOrganization) {
                  this.router.navigate(["/auth/org-not-found"], {
                    queryParams: { returnUrl: state.url },
                  });
                  resolve(false);
                }
              }
              resolve(true);
              return;
            } catch (error) {
              console.error(error);
            }
          }

          if (!user) {
            await this.authService.setHomeURL(state.url);
          }

          this.router.routerState.root.queryParams.subscribe(async (params) => {
            if (params && params.redirectUrl) {
              this.redirectUrl = params.redirectUrl;
            }
          });

          if (user) {
            if (
              !user.emailVerified &&
              user.providerData.find(
                (provider) => provider.providerId === "password"
              )
            ) {
              await this.router.navigate(["/auth/verification-required"], {
                queryParams: { redirectUrl: this.redirectUrl },
              });
              resolve(false);
              return;
            } else {
              if (environment.app === "player") {
                const hasOrganization = await this.hasOrganization();
                if (!hasOrganization) {
                  if (whiteListedRoutes.includes(next.routeConfig.path)) {
                    resolve(true);
                    return;
                  }

                  this.router.navigate(["/auth/org-not-found"], {
                    queryParams: { returnUrl: state.url },
                  });
                  resolve(false);
                } 
              }

              resolve(true);
            }
          } else {
            await this.navCtrl.navigateRoot(["/welcome"]);
            resolve(false);
          }
        });
    });
  }

  async hasOrganization() {
    const user = await this.modelProvider.user.getMe();
    return firstValueFrom(await user.organizationMembers$.pipe(
        map((members) => {
          return members?.length ? true : false;
        }),
    ));
  }
}
