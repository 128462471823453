import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular/standalone';
import { AppUpdateComponent } from './app-update.component';
import { ModelProvider } from '../models/general/model.provider';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { satisfies } from "semver";
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  currentVersion: string;
  minimumVersion: string;

  constructor(
    private modalController: ModalController,
    private modelProvider: ModelProvider,
    private platform: Platform,
    private auth: AuthService
  ) {
    this.init().then();
  }

  public async init() {
    await this.platform.ready();
    const currentVersion = await this.auth.getAppVersion();
    const modal = await this.modalController.create({
      component: AppUpdateComponent,
      backdropDismiss: false,
      cssClass: 'auto-height app-update'
    });
    this.$updateRequired(currentVersion).subscribe(async isRequired => {
      if (isRequired) {
        await modal.present();
      }
    });
  }

  public $updateRequired(currentVersion): Observable<boolean> {
    return this.modelProvider.config.minimumVersion$
      .pipe(map(minimumVersion => {
        let minVersion = null;
        if (this.platform.is('ios') && minimumVersion[environment.app] && minimumVersion[environment.app]['ios']) {
          minVersion = minimumVersion[environment.app]['ios'];
        }
        if (this.platform.is('android') && minimumVersion[environment.app] && minimumVersion[environment.app]['android']) {
          minVersion = minimumVersion[environment.app]['android'];
        }
        if (!this.platform.is('cordova') && minimumVersion[environment.app] && minimumVersion[environment.app]['pwa']) {
          minVersion = minimumVersion[environment.app]['pwa'];
        }

        this.minimumVersion = minVersion;
        this.currentVersion = currentVersion;

        if (minVersion) {
          return !satisfies(currentVersion, minVersion);
        }

        return false;
      }));
  }
}
