
import { Route } from '@angular/router';
import { environment } from '../environments/environment';
import { ScriptResolver } from './script/script.resolver';
import { AuthGuard } from './auth/auth.guard';
import { WelcomePageComponent } from './welcome/welcome-page.component';

export const appRoutes: Route[] = [
  { path: '', redirectTo: environment.homeURL, pathMatch: 'full' },
  { path: 'welcome',component: WelcomePageComponent },
  { path: 'auth/org-not-found', loadComponent: () => import('./organization/organization-not-found/organization-not-found.component').then(m => m.OrganizationNotFoundComponent) },
  { path: 'auth/login', loadComponent: () => import('./auth/login/login.page').then(m => m.LoginPage) },
  { path: 'auth/sign-up', loadComponent: () => import('./auth/sign-up/sign-up.page').then(m => m.SignUpPage) },
  {
    path: 'auth/verification-required',
    loadComponent: () => import('./auth/verification-required/verification-required.page').then(m => m.VerificationRequiredPage)
  },
  {
    path: 'analytics',
    loadComponent: () => import('./analytics/analytics.page').then(m => m.AnalyticsPage)
  },
  {
    path: 'analytics/:scriptID/reporting',
    loadComponent: () => import('./reporting/script-report/script-report.page').then(m => m.ScriptReportPage),
    resolve: { script: ScriptResolver }
  },
  {
    path: 'organize',
    loadChildren: () => import("./event/event.routes").then(m => m.eventRoutes),
  },
  {
    path: 'script',
    redirectTo: 'design'
  },
  {
    path: 'design',
    loadChildren: () => import("./designer/designer.routes").then(m => m.designerRoutes),
  },
  {
    path: 'user-profile',
    loadComponent: () => import('./user-profile/user-profile-page.component').then(m => m.UserProfilePageComponent),
  },
  {
    path: 'superadmin',
    loadChildren: () => import('./superadmin/superadmin.routes').then(m => m.superadminRoutes),
  },
  {
    path: "teams",
    loadChildren: () => import("./teams/teams.routes").then(m => m.teamRoutes),
  },
  {
    path: 'ai/create-game',
    loadComponent: () => import("./ai/content-generator-demo/content-generator-demo.component").then(m => m.ContentGeneratorDemoComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'play-landing',
    loadComponent: () => import("./playing/landing/play-landing.component").then(m => m.PlayLandingComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "playing",
    loadChildren: () => import("./playing/playing.routes").then(m => m.playingRoutes)
  },
  {
    path: 'teaser-modal',
    loadComponent: () => import("./playing/browse/script-details/teaser-modal/teaser-modal.page").then(m => m.TeaserModalPage)
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.routes').then(m => m.organizationRoutes),
  },
];