import { DocumentReference, QueryConstraint, collection, Timestamp, refEqual } from "@angular/fire/firestore";
import { FirestoreModel } from "../firebase/firestore-model";
import { Model } from "./general/model";
import { Player } from "./player.model";
import { Response } from "./response";
import { Observable } from "rxjs";
import { Feed } from "./feed";
import { Task } from "./task.model";
import { LayoutItem } from "./task-layout-item";
import { Play } from "./play.model";
import { QuoteLayoutItem } from "./layout-item-quote";
import { Role } from "./role.model";
import { User } from "./user.model";

export type QuoteInfo = {
  response: Response,
  task: Task,
  player: Player,
  role: Role,
  user: User
}

export class FeedPost extends FirestoreModel<FeedPost>{
  type: 'message' | 'invite' | 'task-upcoming' | 'response';
  text: string;
  userRef: DocumentReference;
  createdAt: Timestamp;
  imageURL: string;
  videoURL: string;

  taskRef: DocumentReference;

  referTo: DocumentReference;
  className: string;

  quote: QuoteInfo

  protected rules() {
    return {
      text: {
        [Model.RULE_DEFAULT]: ''
      },
      imageURL: {},
      videoURL: {},
      type: {
        [Model.RULE_DEFAULT]: 'message'
      },
      userRef: {},
    };
  }

  public findAllByFeed(feed: Feed, queryFn?: QueryConstraint[]): Observable<FeedPost[]> {
    const postsCollectionRef = collection(feed.getReference(), 'posts');
    return this.findAllBy(null, postsCollectionRef);
  }

  protected instantiate(path: string, data, options?: any) {
    return new FeedPost(path, data, options, this.modelProvider);
  }

  icon = 'chat';

  createFromResponse(response: Response, player: Player, task: Task, options?: { className?: string, quote?: QuoteInfo }) {
    const quoteInfo = options?.quote;
    const text = this.modelProvider.translate.instant('playing/feed/post/response/title/prefix') + ': ' + task.title;
    const imageURL = response.photoURL;
    const videoURL = response.videoURL;
    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/responses/' + response.id, {
      type: 'response',
      userRef: player.userRef,
      imageURL: imageURL,
      videoURL: videoURL,
      text: text,
      createdAt: response.createdAt,
    }, {
      isNew: true
    });
    post.referTo = task.ref;
    post.icon = task.inputTypeIcon;
    post.className = options?.className;
    if (quoteInfo) {
      post.quote = quoteInfo;
    }
    return post;
  }

  createFromUpcomingTask(player: Player, task: Task, options?: {
    quote: {
      response: Response,
      task: Task,
      player: Player,
      role: Role,
      user: User
    }
  }) {
    const quoteInfo = options?.quote;

    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/tasks/' + task.id, {
      type: 'task-upcoming',
      userRef: player.userRef,
      imageURL: quoteInfo?.response?.photoURL ? quoteInfo.response.photoURL : null,
      videoURL: quoteInfo?.response?.videoURL ? quoteInfo.response.videoURL : null,
      text: quoteInfo ? quoteInfo.role.title + ' sent you: ' + task.title : this.modelProvider.translate.instant('playing/feed/post/task-upcoming/title/prefix') + ': ' + task.title,
      createdAt: Timestamp.now(),
    }, {
      isNew: true
    });
    post.referTo = task.ref;
    post.icon = 'play_circle';
    return post;
  }

  createInvitePost(play: Play, player: Player, code: string) {
    const post = this.instantiate(player.playRef.path + '/feeds/team' + player.teamRef.id + '/invite/' + player.id, {
      type: 'invite',
      userRef: player.userRef,
      text: this.modelProvider.translate.instant('playing/feed/post/invite/title/prefix') + ' ' + code,
      createdAt: Timestamp.now(),
    }, {
      isNew: true
    });
    post.icon = 'group_add';
    return post;
  }

  public static getQuote(task: Task, tasks: Task[], players: Player[], roles: Role[], users: User[]) {
    const quotedTask = tasks.find(_task => _task.ref.path === (task.layout.getItemByType(LayoutItem.TYPE_QUOTE, false) as QuoteLayoutItem)?.properties?.taskRef?.path);
    if (quotedTask) {
      const quotedPlayer = players.find(player => player.responses.getByID(quotedTask.id)?.done);
      if (quotedPlayer) {
        const quotedResponse = quotedPlayer.responses.getByID(quotedTask.id);
        const role = roles.find(role => role.ref.path === quotedPlayer.roleRef.path);
        const user = users.find(user => user.ref.path === quotedPlayer.userRef.path)
        if (quotedResponse) {
          return {
            response: quotedResponse,
            player: quotedPlayer,
            task: quotedTask,
            role: role,
            user: user
          }
        }
      }
    }
    return null;
  }

  get userCategory() {
    if(refEqual(this.userRef,this.modelProvider.user.meReference )){
      return 'me';
    }
    if(refEqual(this.userRef, this.modelProvider.user.facilitatorRef)){
      return 'facilitator';
    }
    return 'player';
  }
}

