import { FirestoreField } from '../firebase/firestore-field';

import { ModelProvider } from './general/model.provider';
import { Task } from './task.model';

// tslint:disable-next-line: max-line-length
export type mediatype =
  string
  | 'text'
  | 'image'
  | 'video'
  | 'image-360'
  | 'image-360'
  | 'video-360'
  | 'input-choice'
  | 'input-text'
  | 'input-photo'
  | 'input-video';

export class LayoutItem extends FirestoreField {
  public static TYPE_QUOTE = 'response';
  public static TYPE_IMAGE = 'image';
  public static TYPE_IMAGE_360 = 'image-360';
  public static TYPE_VIDEO = 'video';
  public static TYPE_VIDEO_360 = 'video-360';
  public static TYPE_TEXT = 'text';
  public static TYPE_INPUT_CHOICE = 'input-choice';
  public static TYPE_INPUT_TEXT = 'input-text';
  public static TYPE_INPUT_PHOTO = 'input-photo';
  public static TYPE_INPUT_VIDEO = 'input-video';

  // tslint:disable-next-line: max-line-length
  public type: mediatype;
  public properties: any;
  public order?: number;

  public static instantiate(data: any,
                            documentInstance?: Task,
                            options?: any,
                            modelProvider?: ModelProvider): LayoutItem {
    return new LayoutItem(data, documentInstance, options, modelProvider);
  }

  protected setProperties(data) {
    if(!this.properties) {
      this.properties = {};
    }

    const properties = this.properties;
    this.properties = { properties, ...data};
  }

  public rules() {
    return {
      type: {},
      properties: {},
      order: {}
    };
  }

}
