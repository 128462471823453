import { Injectable } from "@angular/core";
import { Organization } from "../core/models/organization.model";
import { firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { ModelProvider } from "../core/models/general/model.provider";
import { switchMap, map } from "rxjs/operators";
import { Script } from "../core/models/script.model";
import { DocumentReference, refEqual, doc } from "@angular/fire/firestore";
import { Play } from "../core/models/play.model";
import { Workspace } from "../core/models/workspace.model";
import { ModalController } from "@ionic/angular/standalone";
import { UpgradeRequiredModalComponent } from "./upgrade-required-modal/upgrade-required-modal.component";
import { Router } from "@angular/router";
import { OrganizationJoinComponent } from "./organization-join/organization-join.component";
import { AuthService } from "../auth/auth.service";
import { Storage } from "@ionic/storage-angular";
import { AcceptInvitationModalComponent } from "./accept-invitation-modal/accept-invitation-modal.component";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  activeOrganization$: ReplaySubject<Organization> =
    new ReplaySubject<Organization>(1);

  constructor(
    private modelProvider: ModelProvider,
    private modalController: ModalController,
    private router: Router,
    private auth: AuthService,
    private storage: Storage
  ) {}

  private _organizations$: Observable<Organization[]>;
  get organizations$() {
    if (this._organizations$) {
      return this._organizations$;
    }

    this._organizations$ = this.modelProvider.user.getMe$().pipe(
      switchMap((user) => user.organizationsPartOf$),
      map((organizations) =>
        organizations.sort((organization) => {
          return organization?.id === this.modelProvider.user.meReference.id
            ? -1
            : 1;
        })
      )
    );

    return this._organizations$;
  }

  private activeOrgazanition: Organization;
  async setOrganization(_organization: Organization) {
    const organization =
      _organization || this.modelProvider.organization.getOrphanOrganization();

    if (!organization.isOrphan()) {
      const plan = await firstValueFrom(organization.plan$);
      if ((window as any)?.mixpanel?.add_group) {
        (window as any).mixpanel.add_group("organization-id", organization.id);
        (window as any)?.mixpanel
          ?.get_group("organization-id", organization.id)
          .set(
            {
              $name: organization.title,
              $created: organization.createdAt.toDate(),
              "plan-id": plan.id,
              Plan: plan.title,
            },
          );
      }
    }

    if (
      this.activeOrgazanition &&
      this.activeOrgazanition.ref.path === organization.ref.path
    ) {
      return;
    }

    await this.setLatestStoredOrganization(organization);

    this.activeOrgazanition = organization;
    this.activeOrganization$.next(organization);
  }

  async getDefaultActiveOrganization() {
    const latestStoredOrganization = await this.getLatestStoredOrganization();
    if (latestStoredOrganization) {
      return latestStoredOrganization;
    }
    const organizationMembers = await firstValueFrom(this.modelProvider.organizationMember.findAllMy$);
    if (organizationMembers.length > 0) {
      let membersAsOwner = organizationMembers;
      if(environment.app==='designer'){
        membersAsOwner = organizationMembers.filter((member)=>member.level !== 'player');
      }
      const organization = await firstValueFrom(this.modelProvider.organization.findByRef(membersAsOwner[0].organizationRef));
      return organization;
    }

    return null;
  }

  static LATEST_ORGANIZATION_STORAGE_KEY = "lastActiveOrganizationID";
  async getLatestStoredOrganization() {
    const storedID: string = await this.storage.get(
      OrganizationService.LATEST_ORGANIZATION_STORAGE_KEY
    );
    if (storedID) {
      return firstValueFrom(await this.modelProvider.organization.findByID(storedID));
    }
    return null;
  }
  async setLatestStoredOrganization(organization: Organization) {
    await this.storage.set(
      OrganizationService.LATEST_ORGANIZATION_STORAGE_KEY,
      organization.id
    );
  }

  async setByWorkspace(workspace: Workspace) {
    const workspaceMember = await workspace.getMyMember();
    if (workspaceMember.id === "guest") {
      if (this.activeOrgazanition) {
        return;
      } else {
        await this.setOrganization(
          this.modelProvider.organization.getOrphanOrganization()
        );
        return;
      }
    }

    if (workspace.ownerOrganizationRef) {

      const organizations = await firstValueFrom(this.organizations$);

      const organization = organizations.find((organization) =>
       refEqual(organization.ref, workspace.ownerOrganizationRef)
      );

      if (organization) {
        await this.setOrganization(organization);
        return organization;
      }
    }

    await this.setOrganization(null);
  }

  async setByWorkspaceRef(workspaceRef: DocumentReference) {
    const workspace = await firstValueFrom(this.modelProvider.workspace.findByRef(workspaceRef));
    return await this.setByWorkspace(workspace);
  }

  async setByScript(script: Script) {
    return await this.setByWorkspaceRef(script.workspaceRef);
  }

  async setByPlay(play: Play) {
    return await this.setByWorkspaceRef(play.workspaceRef);
  }

  async showWorkspaceLimitWarning(organization?: Organization) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        feature: "workspace",
        organization: organization,
      },
    });
    await modal.present();
  }

  async showScriptLimitWarning(organization?: Organization, title?: undefined) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        title: title,
        feature: "script",
        organization: organization,
      },
    });
    await modal.present();
  }

  async showDesignerLimitWarning() {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        feature: "designer",
      },
    });
    await modal.present();
  }

  async showWarning(title: string) {
    const modal = await this.modalController.create({
      component: UpgradeRequiredModalComponent,
      cssClass: "upgrade-reqired auto-height",
      componentProps: {
        title: title,
      },
    });
    await modal.present();
  }

  async getAnalyticsParams() {
    const organization = await firstValueFrom(this.activeOrganization$);
    const additionalParams: any = {};
    if (!organization.isOrphan()) {
      additionalParams["organization-id"] = organization.id;
      additionalParams["organization-title"] = organization.title;

      const plan = await firstValueFrom(organization.plan$);
      additionalParams["plan-title"] = plan.title;
      additionalParams["plan-id"] = plan.id;
    }

    return additionalParams;
  }

  public lastActiveOrganizationRef() {}

  public async getOrganizationByScriptInfo(scriptPath: string) {
    const result = await this.modelProvider.callFunction( "organizationGetBy",{
        scriptPath: scriptPath.trim(),
    }) as {
      result: "ok" | "error";
      error?: string;
      script?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    }

    if (result.error) {
      throw new Error(result.error);
    }

    return result;
  }

  public async showPopupOrganizationByScriptInfo(result) {
    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.script) {
      await this.auth.setHomeURL(
        `playing/browse/design-details/${result.script.id}`
      );
    }

    if (result.member && result.script) {
      await this.auth.navigateToHome();
    }

    // Not being part of the organization and the organization is found
    if (!result.member && result.organization) {
      if (result.organization.imageURL) {
        await loadImage(result.organization.imageURL).catch();
      }
      const modal = await this.modalController.create({
        component: OrganizationJoinComponent,
        cssClass: "organization-join auto-height",
        componentProps: {
          organization: result.organization,
        },
      });
      await modal.present();
      const data = await modal.onDidDismiss<boolean>();
      if (data.data === true) {
        return result;
      } else {
        throw new Error("closed");
      }
    }
  }

  /**
   *
   * @param scriptPath
   * @param handle Show popup for join. If false the function will return the result
   * @returns
   */
  public async getOrganizationByScript(scriptPath: string, handle = true) {
    const result = await this.modelProvider.callFunction( "organizationGetBy",{
        scriptPath: scriptPath.trim(),
    }) as {
      result: "ok" | "error";
      error?: string;
      script?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    if (handle) {
      if (result.script) {
        await this.auth.setHomeURL(
          `playing/browse/design-details/${result.script.id}`
        );
      }
      if (result.member && result.script) {
        await this.auth.navigateToHome();
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        const data = await modal.onDidDismiss<boolean>();
        if (data.data === true) {
          return result;
        } else {
          throw new Error("closed");
        }
      }
    }

    return result;
  }

  public async getOrganizationByPath(path: string, handle = true) {
    const result = await this.modelProvider.callFunction( "organizationGetBy",{
        organizationPath: path.trim(),
    }) as {
      result: "ok" | "error";
      error?: string;
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    if (handle) {
      if (result.member) {
        await this.auth.navigateToHome();
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      }
    }

    return result;
  }
  private isModalPresent = false;

  public async acceptInvitationToJoinOrganization(path: string, inviter: string, invitee: string, level: string, userExists: string) {

    if(this.isModalPresent) return false;
    this.isModalPresent = true;

    const result = await this.modelProvider.callFunction( "organizationGetBy",{
      organizationPath: path.trim(),
    }) as {
      result: "ok" | "error";
      error?: string;
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
        isInvitationAccepted: boolean;
      };
    };

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      return false;
    }

    if(result.member && result.member.level === level && result.member.isInvitationAccepted) {
      return false;
    }

    if (result.organization) {
      if (result.organization.imageURL) {
        await loadImage(result.organization.imageURL).catch();
      }
      const modal = await this.modalController.create({
        component: AcceptInvitationModalComponent,
        cssClass: "organization-join auto-height",
        componentProps: {
          organization: result.organization,
          inviter,
          invitee,
          level,
          userExists
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (!data || data.action === 'cancel' || data.action === 'error') {
        return false;
      }else{
        return true;
      }
    }
    this.isModalPresent = false;
    return false;
  }

  public async getOrganizationByCode(code: string, handle = true) {
    const result = await this.modelProvider.callFunction( "organizationGetBy",{
      code: code.toLocaleUpperCase().trim(),
    }) as {
      result: "ok" | "error";
      error?: string;
      play?: {
        id: string;
        path: string;
      };
      organization: {
        path: string;
        title: string;
        imageURL: string;
      };
      member?: {
        path: string;
        level: string;
      };
    }

    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = url;
      });

    if (result.error) {
      throw new Error(result.error);
    }

    if (handle) {
      if (result.play) {
        await this.auth.setHomeURL(`playing/event/${result.play.id}`);
      }
      if (result.member && result.play) {
        await this.auth.navigateToHome();
      }

      if (!result.member && result.organization) {
        if (result.organization.imageURL) {
          await loadImage(result.organization.imageURL).catch();
        }
        const modal = await this.modalController.create({
          component: OrganizationJoinComponent,
          cssClass: "organization-join auto-height",
          componentProps: {
            organization: result.organization,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      }
    }

    return result;
  }

  public async joinToOriganization(
    organizationRef: DocumentReference,
    handle = true
  ) {
    const response = await this.modelProvider.callFunction( "organizationJoin",{
      organizationPath: organizationRef.path,
    }) as {
      workspaces: Array<{
        path: string;
        id: string;
        imageURL: string;
        title: string;
        member: {
          path: string;
          id: string;
          level: string;
        };
      }>;
    };

    if (handle) {
      const user = await this.modelProvider.user.getMe();
      const member = await firstValueFrom(this.modelProvider.organizationMember.findByRef(doc(this.modelProvider.fsDB, 'organizations', organizationRef.id, 'members', user.id)));
        
      if (member) {
        await this.auth.changeOrganization(organizationRef);
        const organization = await firstValueFrom(this.modelProvider.organization.findByRef(organizationRef));
        await this.setOrganization(organization);
        await this.auth.navigateToHome();
      }
    }

    return response;
  }

  public async getDefaultOrganization() {
    return firstValueFrom(this.modelProvider.organization.findByRef(this.defaultOrganizationRef));
  }

  public get defaultOrganizationRef() {
    return doc(this.modelProvider.fsDB, "organizations","");
  }
}
